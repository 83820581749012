import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getStubRegionDeliveryFrom,
  getStubRegionName,
  getStubRegionNamePrepositional,
} from 'features/stub-region/ducks/get-stub-region-data';
import { LinkButton, Typography } from 'fronton-react';
import { MapPinIcon, ShoppingCartSimpleIcon, TruckIcon } from '@fronton/icons-react';
import { analyticsUtils } from 'utils';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { useTranslation } from 'react-i18next';
import { MainInfo } from '../main-info';
import { courierInfo, deliveryRules, deliveryRulesLink, howToOrderInfo, howToOrderTitle } from './styles';
import { createMainUrl } from 'common/constants';
import { getRegionFromHost } from 'features/page-model/ducks/get-model';
import { DeliveryStaticData } from 'pages/region-stub-page/delivery-static-data';
import { SaleOfProjects } from './sale-of-projects';
import { ListIntroduction } from './list-introduction';
import { DefaultIntroduction } from './default-introduction';

export const DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES = 'main-info-with-delivery-rules';
export const DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES_LINK = 'main-info-with-delivery-rules-link';
export const DATA_QA_HEADER = 'main-info-header';
export const DATA_QA_PICKUP_POINT = 'main-info-list-item-pickup-point';

export const MainInfoWithDelivery = () => {
  const regionNamePrepositional = useSelector(getStubRegionNamePrepositional) || '';
  const regionName = useSelector(getStubRegionName) || '';
  const hostRegionName = useSelector(getRegionFromHost);

  const { mobile, smMobile } = useBreakpoints() || {};
  const { regionLink = '', note, regionNameGenitive = '' } = useSelector(getStubRegionDeliveryFrom) || {};
  const { t } = useTranslation();
  const legalName = t('legalName');

  const title = `Доставляем в ${regionName}`;

  const sandGaDeliveryRulesClickEvent = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'stubPage', // блок, в котором расположен элемент
      elementName: title, // название блока (заголовок)
      elementText: `Условия доставки ${legalName} в ${regionNamePrepositional}`, // заголовок пункта
    });
  }, [legalName, regionNamePrepositional, title]);

  const isMobile = mobile || smMobile;
  const textVariant = isMobile ? 'm' : 'l';

  const mainUrl = createMainUrl(regionLink);
  const deliveryUrl = createMainUrl(regionLink) + 'service/dostavka/';
  const staticData = DeliveryStaticData[hostRegionName] || DeliveryStaticData.default;

  return (
    <MainInfo title={title} buttonText="Перейти к покупкам" regionLink={regionLink}>
      <Typography variant={textVariant} size="body_accent" className={courierInfo} data-qa={DATA_QA_HEADER}>
        {staticData.header}
      </Typography>
      {staticData.isDefaultGeneralInfo ? (
        <DefaultIntroduction
          legalName={legalName}
          regionName={regionName}
          regionNameGenitive={regionNameGenitive}
        />
      ) : (
        <ListIntroduction url={mainUrl} deliveryNameGenitive={staticData.deliveryNameGenitive} />
      )}
      <Typography
        variant="m"
        size="body_long"
        className={deliveryRules}
        data-qa={DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES}
      >
        {note}
      </Typography>
      <LinkButton
        as="a"
        size="m"
        variant="underline"
        className={deliveryRulesLink}
        href={deliveryUrl}
        onClick={sandGaDeliveryRulesClickEvent}
        data-qa={DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES_LINK}
      >
        Условия доставки {legalName} в&nbsp;{regionNamePrepositional}
      </LinkButton>
      {!staticData.isDefaultGeneralInfo && <SaleOfProjects />}
      <Typography variant="l" size="body_accent" className={howToOrderTitle}>
        Как оформить заказ:
      </Typography>
      <div className={howToOrderInfo}>
        <ShoppingCartSimpleIcon type="outline" size="l" color="text-primary" />
        <Typography variant="m" size="body_long">
          Выберите и добавьте товары в корзину
        </Typography>
        <TruckIcon type="outline" size="l" color="text-primary" />
        <Typography variant="m" size="body_long">
          Для доставки курьером выберите опцию «Доставка», укажите адрес в {regionNamePrepositional},
          выберите нужную дату и оплатите заказ
        </Typography>
        <MapPinIcon type="outline" size="l" color="text-primary" />
        <Typography variant="m" size="body_long" data-qa={DATA_QA_PICKUP_POINT}>
          {staticData.isDefaultPickupText ? (
            <DefaultPickupPointText />
          ) : (
            <PickupPointText regionNameGenitive={regionNameGenitive} />
          )}
        </Typography>
      </div>
    </MainInfo>
  );
};

const DefaultPickupPointText = () => {
  return (
    <>Для доставки в пункт выдачи выберите опцию «Самовывоз», затем удобный ПВЗ на карте и оплатите заказ</>
  );
};

const PickupPointText = ({ regionNameGenitive }: { regionNameGenitive: string }) => {
  return <>Для самовывоза в магазине {regionNameGenitive} выберите опцию «Самовывоз» и оплатите заказ</>;
};
