import Cookies from 'universal-cookie';
import { getHostname } from './get-hostname';
import { getDomain } from './get-domain';

export const removeUserRegionCookie = (host: string | null) => {
  const cookies = new Cookies();
  const userRegionCookie = '_userRegion';
  const currentCookieValue = cookies.get(userRegionCookie);
  const hostname = getHostname(host || '');
  const domain = getDomain(hostname);

  if (currentCookieValue) {
    cookies.remove(userRegionCookie, { domain: `.${domain}`, path: '/' });
  }
};
