import { PHONE_NUMBER, PHONE_NUMBER_SEO } from 'common/constants';
import React, { ComponentProps } from 'react';

export const PhoneLinkBase = (props: ComponentProps<'a'>) => {
  return (
    <a {...props} href={`tel:${PHONE_NUMBER_SEO}`}>
      {props.children || PHONE_NUMBER}
    </a>
  );
};
