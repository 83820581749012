export const REGION_STUB_PATH = '/';
export const REGIONS_PATH_NAME = 'regions';
export const MOBILE_VIEW_SEARCH_PARAM_LEGACY = 'mobile-view';
export const MOBILE_VIEW_SEARCH_PARAM = 'mobileView';
export const DATA_TESTID_ATTRIBUTE = 'data-testid';

export const HIDE_BRANDS_INTERNAL_ID = 'hide_brands';

export const PHONE_NUMBER = '8 800 700 00 99';
export const PHONE_NUMBER_SEO = '88007000099';

export const createMainUrl = (region: string) => `https://${region}.lemanapro.ru/`;
