import React from 'react';
import { Layout } from 'fronton-react';
import { Remote } from '@okapi/remote';
import { SeoDescriptionSection } from 'features/seo-description';
import { AnalyticsPageMeta } from 'features/analytics';
import { HistoryCarousel } from 'common/components/history-carousel';
import { useSelector } from 'react-redux';
import { getPathname, getRegionId } from 'features/page-model/ducks/get-model';
import { getIsAuthorized } from 'common/selectors';
import { useGaPageview } from 'features/analytics/hooks';
import { GreyBackground, RoundedLayout } from './styles';
import { RebrandSection, NewLemana, CardsSection } from './components';
import { useHistoryItems } from './hooks';

export const RebrandingKz: React.FC = () => {
  const path = useSelector(getPathname);
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';
  const regionId = useSelector(getRegionId);
  const historyItems = useHistoryItems();

  useGaPageview(path, isAuthorized, regionId);
  return (
    <>
      <AnalyticsPageMeta />
      <Remote widget="header-footer/header" id="about-header" />
      <RebrandSection />
      <GreyBackground>
        <RoundedLayout>
          <Layout>
            <NewLemana />
          </Layout>
        </RoundedLayout>
      </GreyBackground>
      <CardsSection />
      <HistoryCarousel items={historyItems} />
      <Layout>
        <SeoDescriptionSection />
      </Layout>
      <Remote widget="header-footer/footer" id="about-footer" />
    </>
  );
};
