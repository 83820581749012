import React from 'react';

type Props = {
  fill?: boolean;
};

export const CoopIcon: React.FC<Props> = ({ fill }) => {
  const color = '#BF8900';
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        d="M37.5 29.6687L30.6 36.5687C30.4119 36.7445 30.1841 36.8722 29.9359 36.9408C29.6878 37.0093 29.4267 37.0168 29.175 36.9624L18.3 34.2437C18.1013 34.1883 17.9162 34.0925 17.7562 33.9624L7.5 25.9562L13.6125 14.4624L23.2313 11.6499C23.573 11.5519 23.9385 11.5785 24.2625 11.7249L30.75 14.6687H26.8688C26.674 14.668 26.481 14.7057 26.3008 14.7797C26.1206 14.8537 25.9568 14.9625 25.8187 15.0999L18.4688 22.4312C18.3187 22.5852 18.203 22.7694 18.1295 22.9715C18.0559 23.1736 18.0262 23.3891 18.0421 23.6035C18.0581 23.818 18.1194 24.0267 18.222 24.2157C18.3247 24.4047 18.4663 24.5697 18.6375 24.6999L19.65 25.4687C20.6898 26.2447 21.9525 26.664 23.25 26.664C24.5475 26.664 25.8102 26.2447 26.85 25.4687L29.25 23.6687L37.5 29.6687Z"
        fill={fill ? color : undefined}
      />
      <path
        d="M45.1312 23.8371L40.5 26.1434L34.5 14.6684L39.1875 12.3246C39.5359 12.1471 39.9403 12.1144 40.3126 12.2339C40.6849 12.3533 40.9949 12.6151 41.175 12.9621L45.7875 21.7934C45.881 21.9702 45.9383 22.1639 45.9558 22.3631C45.9734 22.5623 45.951 22.7631 45.8898 22.9535C45.8287 23.1439 45.7301 23.3202 45.5998 23.4719C45.4695 23.6237 45.3102 23.7479 45.1312 23.8371V23.8371Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49968 25.9557L2.86843 23.6307C2.69011 23.5433 2.53118 23.421 2.4011 23.2709C2.27102 23.1209 2.17243 22.9462 2.11121 22.7573C2.04999 22.5684 2.02738 22.3691 2.04472 22.1713C2.06207 21.9734 2.11902 21.7811 2.21218 21.6057L6.82468 12.7745C7.00512 12.4277 7.31382 12.165 7.68506 12.0424C8.05629 11.9199 8.46071 11.9471 8.81218 12.1182L13.4997 14.462L7.49968 25.9557Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5 26.1436L37.5 29.6686L30.6 36.5686C30.4119 36.7444 30.1841 36.8721 29.9359 36.9407C29.6878 37.0092 29.4267 37.0167 29.175 36.9623L18.3 34.2436C18.1013 34.1882 17.9162 34.0924 17.7562 33.9623L7.5 25.9561"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5002 29.669L29.2502 23.669L26.8502 25.469C25.8104 26.245 24.5476 26.6643 23.2502 26.6643C21.9527 26.6643 20.69 26.245 19.6502 25.469L18.6377 24.7002C18.4665 24.57 18.3248 24.405 18.2222 24.216C18.1196 24.027 18.0582 23.8183 18.0423 23.6038C18.0263 23.3894 18.0561 23.1739 18.1297 22.9718C18.2032 22.7697 18.3188 22.5855 18.4689 22.4315L25.8189 15.1002C25.957 14.9628 26.1208 14.854 26.301 14.78C26.4811 14.706 26.6741 14.6683 26.8689 14.669H34.5002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6123 14.4624L23.2311 11.6499C23.5728 11.5519 23.9383 11.5785 24.2623 11.7249L30.7498 14.6687"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 40.9187L15.3563 39.4937C15.1263 39.4418 14.9133 39.3321 14.7375 39.175L10.5 35.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
