/* eslint-disable max-lines */
import React, { FC, memo } from 'react';
import { Typography } from 'fronton-react';
import { cx } from 'linaria';
import {
  OrderedList,
  orderedItem,
  link,
  dottedOrderedList,
  paragraph,
  paragraphHeading,
  subListItem,
  subOrderedList,
  subOrderNumber,
  subParagraph,
  dottedListItem,
  paragraphWithoutNumber,
  dottedListPart,
  orderNumber,
  dottedLi,
  listItem,
  number,
} from '../selling-rules.styles';
import { PHONE_NUMBER_SEO } from 'common/constants';

type Props = {
  legalName: string;
};

const phoneNumber = `tel:${PHONE_NUMBER_SEO}`;

export const Pravila: FC<Props> = memo(({ legalName }) => (
  <>
    <div style={{ margin: 'var(--space-200) 0 0 0' }}>
      <Typography variant="m" size="body_accent" color="text-primary">
        Редакция от&nbsp;21&nbsp;января 2025 года.
      </Typography>
      <Typography
        style={{ marginTop: 'var(--space-100)' }}
        variant="m"
        size="body_accent"
        color="text-primary"
      >
        Правила продажи товаров физическим лицам и&nbsp;применения рекомендательных алгоритмов в
        интернет-магазине &laquo;{legalName}&raquo;
      </Typography>
      <Typography
        variant="s"
        size="body_long"
        as="p"
        style={{ margin: 'var(--space-100) 0 0 0' }}
        color="text-primary"
      >
        Настоящие Правила продажи товаров физическим лицам в&nbsp;Интернет-Магазине &laquo;{legalName}&raquo;
        (далее&nbsp;&mdash; Правила) разработаны в&nbsp;соответствии с&nbsp;положениями
        ГК&nbsp;РФ&nbsp;о&nbsp;розничной купле-продаже (&sect;&nbsp;2 глава 30), Законом
        РФ&nbsp;от&nbsp;07.02.1992&nbsp;N 2300-1 &laquo;О&nbsp;защите прав потребителей&raquo; и
        Постановлением Правительства РФ&nbsp;от&nbsp;31.12.2020 &#8470;&nbsp;2463 &laquo;Об&nbsp;утверждении
        Правил продажи товаров по договору розничной купли-продажи...&raquo;.
      </Typography>
      <Typography
        variant="s"
        size="body_long"
        as="p"
        style={{ margin: 'var(--space-100) 0 0 0' }}
        color="text-primary"
      >
        Настоящие Правила не&nbsp;регулируют продажу товаров и&nbsp;услуг в&nbsp;торговых центрах (магазинах)
        &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot; и&nbsp;не&nbsp;распространяются на&nbsp;отношения,
        возникающие при продаже товаров и&nbsp;услуг в торговых центрах (магазинах) &quot;Лемана
        ПРО&quot;/&quot;Леруа Мерлен&laquo;. Настоящие Правила регулируют отношения между Покупателем, ООО
        &laquo;ЛЕ&nbsp;МОНЛИД&raquo; (до&nbsp;смены наименования 05.03.24&nbsp;г&nbsp;&mdash; ООО
        &laquo;ЛЕРУА МЕРЛЕН ВОСТОК&raquo;) и Продавцами исключительно при продаже дистанционным способом
        товаров с&nbsp;использованием информационно-телекоммуникационной сети &laquo;Интернет&raquo;
        в&nbsp;интернет-Магазине &laquo;{legalName}&raquo;, в&nbsp;том числе с использованием телефонной
        связи или мобильного приложения &laquo;{legalName}&raquo;, а&nbsp;также при оказании в&nbsp;связи
        с&nbsp;такими продажами услуг по&nbsp;доставке товаров путем их&nbsp;пересылки или перевозки.
      </Typography>
    </div>
    <div>
      <OrderedList type="1">
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              1.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Термины и определения.
            </Typography>
          </div>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            Основные понятия, используемые в Правилах, означают следующее:
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Интернет-Магазин&raquo;</b>&nbsp;&mdash; интернет-магазин &laquo;{legalName}&raquo;,
            доступный в&nbsp;сети Интернет на&nbsp;сайте по&nbsp;адресу https://lemanapro.ru или его
            поддоменах (далее&nbsp;&mdash; &laquo;Сайт&raquo;), а&nbsp;также пользователям мобильных
            устройств&nbsp;&mdash; в&nbsp;Мобильном Приложении &laquo;{legalName}&raquo;, в&nbsp;котором
            представлены Товары, предлагаемые Покупателям для приобретения посредством оформления
            и&nbsp;размещения Заказов. В&nbsp;Интернет-магазине продаются как Товары {legalName}, так
            и&nbsp;Товары третьих лиц. {legalName} является Продавцом, либо действует при заключении сделок
            с&nbsp;Клиентами по&nbsp;поручению других Продавцов и&nbsp;от&nbsp;их&nbsp;имени.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Мобильное приложение &laquo;{legalName}&raquo;&raquo;</b>&nbsp;&mdash; программное
            обеспечение, предназначенное для работы на&nbsp;смартфонах, планшетах, и&nbsp;др. мобильных
            устройствах под управлением мобильной операционной системы IOS, Android и&nbsp;др., принадлежащее
            ООО &laquo;ЛЕ&nbsp;МОНЛИД&raquo; и&nbsp;предназначенное для поиска, выбора и&nbsp;заказа Товаров
            в&nbsp;Интернет-магазине. Мобильное приложение &laquo;{legalName}&raquo; доступно владельцам
            мобильных устройств для бесплатного скачивания и&nbsp;установки в&nbsp;магазинах мобильных
            приложений третьих лиц.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Товар&raquo;</b>&nbsp;&mdash; объект материального мира, не&nbsp;изъятый
            из&nbsp;гражданского оборота и&nbsp;представленный к&nbsp;продаже в&nbsp;Интернет-Магазине,
            и&nbsp;имеющийся в&nbsp;наличии на&nbsp;момент Подтверждения готовности Товара к&nbsp;отгрузке.
            Товары, помеченные ярлычком &laquo;Только онлайн-заказ&raquo;, не&nbsp;продаются в&nbsp;Торговых
            центрах &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot;, их&nbsp;можно купить только
            в&nbsp;Интернет-магазине.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Продавец&raquo;</b>&nbsp;&mdash; ООО &laquo;ЛЕ&nbsp;МОНЛИД&raquo; ({legalName}) либо
            иное юридическое лицо или индивидуальный предприниматель, указанные в&nbsp;качестве Продавца
            на&nbsp;странице Товара. Информация о&nbsp;{legalName} приведена на&nbsp;страничке Сайта
            &laquo;Контакты&raquo;. Информация об&nbsp;ином Продавце (фирменное наименование, место
            нахождения, режим работы, государственный регистрационный номер записи о&nbsp;создании
            юридического лица, фамилию, имя, отчество, государственный регистрационный номер записи
            о&nbsp;государственной регистрации физического лица в&nbsp;качестве индивидуального
            предпринимателя) размещена в&nbsp;Интернет-магазине рядом с&nbsp;Товаром под знаком
            &laquo;(i)&raquo; (строчная латинская буква &laquo;i&raquo; в&nbsp;кружочке),
            а&nbsp;в&nbsp;кассовых чеках такой Продавец называется &laquo;поставщиком&raquo;.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Покупатель&raquo;</b>&nbsp;&mdash; любое полностью дееспособное физическое лицо,
            заказывающее, приобретающее Товары на&nbsp;условиях, указанных в&nbsp;Правилах, или использующее
            Товары исключительно для личных, семейных, домашних и&nbsp;иных нужд, не&nbsp;связанных
            с&nbsp;осуществлением предпринимательской деятельности, либо лицо, указанное в&nbsp;качестве
            Получателя приобретаемого товара.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Получатель&raquo;</b>&nbsp;&mdash; Покупатель либо лицо, которое уполномочено
            Покупателем на&nbsp;получение заказанного Товара от&nbsp;имени Покупателя&nbsp;и (или) приемку
            Дополнительных услуг, и&nbsp;данные которого Покупатель сообщает {legalName} при оформлении
            Заказа.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Заказ&raquo;</b>&nbsp;&mdash; оформленный через Сайт, с&nbsp;использованием Мобильного
            приложения &laquo;{legalName}&raquo; либо с&nbsp;использованием телефонной связи запрос
            Покупателя на&nbsp;получение по&nbsp;указанному адресу перечня Товаров, выбранных на&nbsp;Сайте.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Личный кабинет&raquo;</b>&nbsp;&mdash; персональная страница Покупателя на&nbsp;Сайте
            и&nbsp;в&nbsp;Мобильном приложении &laquo;{legalName}&raquo;, для доступа к&nbsp;которой
            необходимы логин и&nbsp;пароль. В&nbsp;Личном кабинете хранится, в&nbsp;частности, информация
            о&nbsp;Покупателе и&nbsp;его Заказах.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Подтверждение получения Заказа&raquo;</b>&nbsp;&mdash; автоматическое направление
            Покупателю системой Сайта информации о&nbsp;получении Заказа Покупателя.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Подтверждение о&nbsp;готовности товара к&nbsp;отгрузке&raquo;</b>&nbsp;&mdash;
            направление {legalName} Покупателю информации о&nbsp;том, что Заказ собран и&nbsp;готов
            к&nbsp;выдаче в&nbsp;Торговом центре &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot; либо собран
            и&nbsp;передан Продавцом Службе доставки для доставки Получателю. Обязательство Продавца
            по&nbsp;передаче Покупателю Товара возникает с&nbsp;момента направления {legalName} Покупателю
            подтверждения о&nbsp;готовности Товара к&nbsp;отгрузке. Если подтверждение о&nbsp;готовности
            Товара к&nbsp;отгрузке не&nbsp;направлено Покупателю, условие признается не&nbsp;наступившим,
            а&nbsp;договор с&nbsp;Покупателем&nbsp;&mdash; не&nbsp;заключенным.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Служба доставки&raquo;</b>&nbsp;&mdash; третье лицо, оказывающее по&nbsp;договору
            с&nbsp;Продавцом услуги по&nbsp;доставке Заказов Получателям.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Постамат&raquo;</b>&nbsp;&mdash; устройство для выдачи Товаров Получателям; терминал
            со&nbsp;множеством автоматизированных ячеек для хранения Заказов, из&nbsp;которых Получатель
            самостоятельно забирает купленные Товары.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Дополнительные услуги&raquo;</b>&nbsp;&mdash; оказываемые Продавцом в&nbsp;связи
            с&nbsp;продажей Товара, включая, но&nbsp;не&nbsp;ограничиваясь, услуги по&nbsp;доставке Товаров
            путем их&nbsp;пересылки почтовыми отправлениями или перевозки.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>&laquo;Рекомендательные технологии&raquo;</b>&nbsp;&mdash; информационные технологии
            предоставления информации на&nbsp;основе сбора, систематизации и&nbsp;анализа сведений,
            относящихся к&nbsp;предпочтениям пользователей сети &laquo;Интернет&raquo;, находящихся
            на&nbsp;территории Российской Федерации. На&nbsp;Сайте и&nbsp;в&nbsp;Мобильном приложении
            применяются рекомендательные технологии. Правила и&nbsp;информация о&nbsp;применении
            рекомендательных технологий изложены на&nbsp;отдельной странице Сайта&nbsp;
            <a
              href="https://lemanapro.ru/legal/pravila-primeneniya-rekomendatelnyh-tehnologiy/"
              className={link}
            >
              https://lemanapro.ru/legal/pravila-primeneniya-rekomendatelnyh-tehnologiy/.
            </a>
          </Typography>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              2.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Общие положения о продаже Товаров
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Настоящий документ, постоянно размещенный в&nbsp;сети Интернет на&nbsp;Сайте
                и&nbsp;в&nbsp;Мобильном приложении, в&nbsp;соответствии со&nbsp;ст.&nbsp;435
                и&nbsp;ч.2&nbsp;ст.&nbsp;437&nbsp;ГК РФ&nbsp;является публичной офертой. Конкретные
                тематические разделы и&nbsp;страницы Сайта с&nbsp;описанием Товаров, информацией
                об&nbsp;условиях их&nbsp;оплаты и&nbsp;доставки, информацией о&nbsp;Продавцах, являются
                неотъемлемой частью настоящих Правил.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;соответствии со&nbsp;статьей 438&nbsp;ГК РФ&nbsp;выражение Покупателем согласия
                с&nbsp;условиями Оферты путём нажатия кнопки &quot;Оформить заказ&quot;/&quot;Перейти
                к&nbsp;оплате&quot; при оформлении Заказа является акцептом Покупателем настоящей оферты.
                Нажимая на&nbsp;кнопку &quot;Оформить заказ&quot;/&quot;Перейти к&nbsp;оплате&quot; при
                оформлении Заказа на&nbsp;Сайте либо давая устное согласие оператору при оформлении Заказа
                по&nbsp;телефону, Покупатель подтверждает свое ознакомление и&nbsp;согласие с&nbsp;настоящими
                Правилами и&nbsp;с&nbsp;условиями индивидуального договора купли-продажи выбранных
                им&nbsp;Товаров и&nbsp;сообщает Продавцу о&nbsp;своём намерении заключить договор розничной
                купли-продажи на&nbsp;условиях настоящей оферты.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае выбора Покупателем способа оплаты &laquo;Картой на&nbsp;сайте&raquo;
                (перечисления аванса в&nbsp;счет будущей сделки) договор считается заключенным, если
                в&nbsp;течение 24&nbsp;часов после осуществления предоплаты Покупатель не&nbsp;получил
                уведомления об&nbsp;отмене Заказа (в&nbsp;связи с&nbsp;невозможностью передать Товар
                Покупателю). В&nbsp;случае выбора Покупателем способа оплаты &laquo;При получении&raquo;
                Договор считается заключенным с&nbsp;момента получения {legalName} способом, описанным
                в&nbsp;п.&nbsp;2.2&nbsp;выше, сообщения Покупателя о&nbsp;намерении заключить договор
                розничной купли-продажи.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель гарантирует, что товары приобретаются им&nbsp;для личных, семейных, домашних
                и&nbsp;иных нужд, не&nbsp;связанных с&nbsp;осуществлением предпринимательской деятельности.
                Покупатель также гарантирует, что ему не&nbsp;менее 18&nbsp;лет, а&nbsp;если ему меньше
                18&nbsp;лет, то&nbsp;право на&nbsp;заказ Товаров предоставлено ему по&nbsp;закону
                и&nbsp;он&nbsp;обладает дееспособностью для акцепта настоящей оферты.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;Интернет-Магазине продаются Товары как из&nbsp;собственного ассортимента {legalName},
                так и&nbsp;Товары иных юридических лиц или индивидуальных предпринимателей, указанных
                в&nbsp;качестве Продавца на&nbsp;странице Товара&nbsp;&mdash; под значком &laquo;(i)&raquo;.
                При заказе Товары каждого Продавца в&nbsp;корзине группируются в&nbsp;отдельный Заказ, каждый
                такой Заказ нужно оформить и&nbsp;оплатить отдельно. В&nbsp;таких случаях в&nbsp;корзине
                появляется соответствующее уведомление.
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  style={{ margin: 'var(--space-100) 0 0 0' }}
                  color="text-primary"
                >
                  {legalName} не&nbsp;является продавцом Товара по&nbsp;договорам купли-продажи Товаров,
                  рядом с&nbsp;описанием которых под значком &laquo;(i)&raquo; приведена информация
                  о&nbsp;Продавце. Все взаимоотношения, связанные с&nbsp;куплей-продажей Товара в&nbsp;таком
                  случае, возникают непосредственно между соответствующим Продавцом и&nbsp;Покупателем,
                  и&nbsp;такой Продавец несет ответственность перед Покупателем в&nbsp;соответствии
                  с&nbsp;действующим законодательством Российской Федерации. {legalName} не&nbsp;является
                  уполномоченным лицом других Продавцов в&nbsp;смысле законодательства о&nbsp;защите прав
                  потребителей.
                </Typography>
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Настоящая оферта адресована только физическим лицам-потребителям. Условиях настоящих Правил
                не&nbsp;распространяются на&nbsp;покупки в&nbsp;Интернет-Магазине Товаров юридическими
                лицами, индивидуальными предпринимателями и&nbsp;участниками программы лояльности
                &laquo;Ключ-карта {legalName}&raquo; (ранее &laquo;Профессиональная карта&raquo;). Правила
                продажи Товаров юридическим лицам и&nbsp;индивидуальным предпринимателям, Правила программы
                лояльности &laquo;Ключ-карта {legalName}&raquo; размещены на&nbsp;Сайте отдельно.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} оставляет за&nbsp;собой право в&nbsp;одностороннем порядке вносить изменения
                в&nbsp;настоящие Правила, в&nbsp;связи с&nbsp;чем Покупателю рекомендуется знакомиться
                с&nbsp;Правилами при каждом оформлении Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Не&nbsp;все Товары, представленные в&nbsp;Интернет-Магазине, предназначены для продажи
                дистанционным способом. Если продажа Товара в&nbsp;Интернет-Магазине подразумевает
                предварительное согласование Продавцом условий договора розничной купли-продажи, в&nbsp;том
                числе согласование наличия, наименования и&nbsp;количества Товара, а&nbsp;также
                в&nbsp;случаях, когда Продавец явно указал в&nbsp;описании Товара, что соответствующий Товар
                не&nbsp;предназначен для продажи дистанционным способом, Товар заказать и&nbsp;купить
                в&nbsp;Интернет-Магазине нельзя.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              3.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Оформление Заказа
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Заказ может быть принят оператором {legalName} по&nbsp;телефону, указанному
                в&nbsp;Интернет-магазине или оформлен Покупателем самостоятельно на&nbsp;Сайте или
                в&nbsp;Мобильном приложении &laquo;{legalName}&raquo;.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Заказ можно оформить и&nbsp;без регистрации в&nbsp;Интернет-магазине. Однако для оперативного
                оформления последующих Заказов Покупателю рекомендуется зарегистрироваться
                в&nbsp;Интернет-магазине и&nbsp;создать Личный кабинет. Для создания Личного кабинета
                Покупателю необходимо указать свой номер мобильного телефона, подтвердив действие
                с&nbsp;помощью ввода поступившего одноразового кода из&nbsp;SMS. Для входа в&nbsp;Личный
                кабинет можно использовать телефон или адрес электронной почты (если он&nbsp;был ранее указан
                в&nbsp;Личном кабинете).
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} не&nbsp;несет ответственности за&nbsp;содержание и&nbsp;достоверность информации,
                предоставляемой Покупателем при оформлении Заказов и&nbsp;при создании Личного кабинета.
                Покупатель несет риски невозможности получения Заказа и/или невозможности получения
                уведомлений по&nbsp;Заказу и/или невозможности получения денежных средств при возврате Заказа
                по&nbsp;причине предоставления им&nbsp;недостоверной/некорректной информации и/или контактных
                данных, предоставления контактных данных третьих лиц (в&nbsp;том числе при оформлении Заказа
                или при создании Личного кабинета).
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                После регистрации данные о&nbsp;Покупателе и&nbsp;Получателе передаются в&nbsp;базу данных{' '}
                {legalName}. {legalName} обеспечивает конфиденциальность персональных данных
                в&nbsp;соответствии с&nbsp;разделом 10&nbsp;настоящих Правил.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель обязуется не&nbsp;сообщать третьим лицам получаемые им&nbsp;от&nbsp;{legalName}{' '}
                и&nbsp;служб доставки одноразовые коды (необходимые для доступа к&nbsp;Личному кабинету, для
                оформления Заказа, для получения Товаров и&nbsp;т.п.) В&nbsp;случае возникновения
                у&nbsp;Покупателя подозрений о&nbsp;возможности их&nbsp;несанкционированного использования
                третьими лицами, Покупатель обязуется незамедлительно уведомить об&nbsp;этом {legalName},
                направив электронное письмо через форму обратной связи по&nbsp;ссылке{' '}
                <a href="/company/obratnaya-svyaz/" className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>
                &nbsp;на&nbsp;Сайте или по&nbsp;телефону&nbsp;{' '}
                <a href={`tel:${PHONE_NUMBER_SEO}`} className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                . {legalName}, в&nbsp;свою очередь, обязуется соблюдать конфиденциальность направляемых
                им&nbsp;Покупателю кодов, и&nbsp;требовать соблюдения конфиденциальности Службами доставки
                и&nbsp;Продавцами.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При оформлении Заказа по&nbsp;телефону Покупатель сообщает оператору {legalName} желаемые
                артикулы Товара. Оператор проверят наличие и&nbsp;цену данных Товаров, согласовывает
                с&nbsp;Покупателем условия доставки и&nbsp;получает подтверждение Заказа у&nbsp;Покупателя.
                Затем {legalName} направляет Покупателю Подтверждение получения Заказа, содержащее
                и&nbsp;уведомление о&nbsp;необходимости оплатить Заказ.
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  style={{ margin: 'var(--space-100) 0 0 0' }}
                  color="text-primary"
                >
                  После получения {legalName} Заказа, сделанного Покупателем самостоятельно (на&nbsp;Сайте
                  или в&nbsp;Мобильном приложении &laquo;{legalName}&raquo;), если Товары не&nbsp;были
                  оплачены непосредственно при оформлении Заказа, {legalName} направляет Покупателю
                  автоматическое Подтверждение получения Заказа и&nbsp;уведомление о&nbsp;необходимости
                  оплатить заказанные Товары.
                </Typography>
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                После получения оплаты Товаров {legalName} направляет Покупателю автоматическое подтверждение
                получения оплаты Товаров с&nbsp;указанием ожидаемой даты доставки. Указанная дата зависит
                от&nbsp;наличия заказанных Товаров на&nbsp;складе Продавца, и&nbsp;от&nbsp;времени,
                необходимого на&nbsp;обработку (сборку, упаковку, передачу в&nbsp;Службу доставки и&nbsp;др.)
                Заказа. В&nbsp;процессе обработки Заказа дата и&nbsp;время доставки Товаров может быть
                изменена, о&nbsp;чём {legalName} сообщает Покупателю. После обработки Заказа Продавцом
                и&nbsp;передачи Товаров Службе доставки для передачи Товаров Покупателю {legalName}{' '}
                направляет Покупателю автоматическое Подтверждение готовности Товаров к&nbsp;отгрузке
                с&nbsp;указанием уточненной ожидаемой даты доставки Товаров.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Общий срок получения Товаров Покупателем также зависит от&nbsp;адреса и&nbsp;региона
                доставки, и&nbsp;от&nbsp;выбранного Покупателем способа доставки Товаров.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.9.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не&nbsp;гарантирует наличие заказанных Товаров на&nbsp;складе на&nbsp;момент сборки
                Заказа. Если Товар был в&nbsp;наличии в&nbsp;момент оформления Заказа, но&nbsp;на&nbsp;момент
                сборки Заказа на&nbsp;складе Продавца Товар уже закончился (либо отсутствовало необходимое
                количество заказанного Товара), {legalName} информирует об&nbsp;этом Покупателя. Обязанность
                Продавца передать Покупателю Товар возникает только с&nbsp;момента направления {legalName}{' '}
                Покупателю Подтверждения о&nbsp;готовности Товара к&nbsp;отгрузке.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.10.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае отсутствия Товара на&nbsp;складе Продавца срок доставки Товаров,
                по&nbsp;согласованию с&nbsp;Покупателем, может быть увеличен на&nbsp;срок не&nbsp;более
                30&nbsp;календарных дней, либо Заказ может быть аннулирован. Если Товар отсутствует частично
                (и&nbsp;если Покупатель сообщит {legalName} в&nbsp;течение 2-х дней о&nbsp;своем согласии
                принять Товар в&nbsp;количестве, имеющемся в&nbsp;наличии у&nbsp;Продавца), то&nbsp;Продавец
                отправляет Покупателю Товары в&nbsp;согласованном с&nbsp;Покупателем количестве.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.11.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} может связаться с&nbsp;Покупателем, не&nbsp;завершившим оформление Заказа, для
                уточнения информации&nbsp;и, при необходимости, оказания Покупателю помощи в&nbsp;оформлении
                Заказа.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              4.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Информация о Товаре
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                4.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} стремится к&nbsp;тому, чтобы описания Товаров в&nbsp;Интернет-Магазине
                соответствовали характеристикам Товаров. Тем не&nbsp;менее, в&nbsp;силу объективных причин
                (особенности индивидуального восприятия человеком, особенности цветопередачи монитора
                компьютера, технические ошибки) описания могут не&nbsp;в&nbsp;полной мере передавать
                достоверную информацию о&nbsp;свойствах и&nbsp;характеристиках Товара, включая цвет, размер,
                форму, количество и&nbsp;цену. В&nbsp;случае возникновения у&nbsp;Покупателя вопросов,
                касающихся свойств и&nbsp;характеристик Товара, перед оформлением Заказа Покупатель должен
                обратиться за&nbsp;консультацией через форму обратной связи на&nbsp;Сайте&nbsp;
                <a href="/company/obratnaya-svyaz/" className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>
                &nbsp;или по&nbsp;телефону&nbsp;
                <a href={phoneNumber} className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                .
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                4.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Информация о&nbsp;Товаре, включая условия его эксплуатации и&nbsp;правила хранения, доводится
                до&nbsp;Покупателя путем размещения в&nbsp;Интернет-Магазине, на&nbsp;Товаре,
                на&nbsp;электронных носителях, прикладываемых к&nbsp;Товару, в&nbsp;самом Товаре
                (на&nbsp;электронной плате внутри Товара в&nbsp;разделе меню), на&nbsp;таре, упаковке,
                ярлыке, этикетке, в&nbsp;технической документации или иным способом, установленным законом.
                Обязанность Продавца, предусмотренная пунктом 3&nbsp;статьи 26.1 Закона Российской Федерации
                &laquo;О&nbsp;защите прав потребителей&raquo;, признается исполненной также в&nbsp;случае
                предоставления Покупателю информации с&nbsp;помощью электронных и&nbsp;иных технических
                средств.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              5.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Передача Товаров Покупателю
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={cx(subListItem, dottedListItem)}>
              <div className={dottedListPart}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="span"
                  className={subOrderNumber}
                  color="text-primary"
                >
                  5.1.
                </Typography>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  className={subParagraph}
                  color="text-primary"
                >
                  Передача Товаров осуществляется выбранным Покупателем при оформлении Заказа способом:
                </Typography>
              </div>
              <ol className={dottedOrderedList}>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    транспортной компанией по&nbsp;адресу, указанному Покупателем (только для Товаров
                    из&nbsp;ассортимента Торгового центра (магазина) &quot;{legalName}&quot;/&quot;Леруа
                    Мерлен&quot;,
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    курьером Службы доставки по адресу, указанному Покупателем,
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    в&nbsp;выбранном Покупателем пункте выдачи или Постамате Службы доставки,
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    самовывозом из&nbsp;выбранного Покупателем Пункта выдачи или Постамата Торгового центра
                    (магазина) &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot;.
                  </Typography>
                </li>
              </ol>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Доступность каждого конкретного способа передачи Товара Клиенту определяется при оформлении
                Заказа. В&nbsp;зависимости от&nbsp;выбранного Товара, способа заказа (на&nbsp;Сайте,
                по&nbsp;телефону, через Мобильное приложение &laquo;{legalName}&raquo;) от&nbsp;класса
                опасности, веса, габаритов и&nbsp;иных характеристик Товаров, региона и&nbsp;адреса доставки
                могут быть доступны только некоторые способы и&nbsp;услуги доставки. {legalName}{' '}
                не&nbsp;предлагает услугу доставки некоторых Товаров (такие Товары можно забрать только
                самовывозом из&nbsp;выбранного Покупателем Торгового центра (магазина) &quot;Лемана
                ПРО&quot;/&quot;Леруа Мерлен&quot;. Услуга доставки курьером или в&nbsp;пункт выдачи или
                в&nbsp;Постамат предоставляется не&nbsp;на&nbsp;всей территории Российской Федерации. Выдача
                Товаров в&nbsp;Пункте выдачи или Постамате осуществляется в&nbsp;рабочие часы
                соответствующего Пункта выдачи или Торгового центра (магазина) &quot;Лемана
                ПРО&quot;/&quot;Леруа Мерлен&quot;. С&nbsp;информацией о&nbsp;графике работы Торговых центров
                (магазинов) &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot; можно ознакомиться
                на&nbsp;Сайте; о&nbsp;графике работы Пунктов выдачи Служб доставки можно осведомиться
                в&nbsp;соответствующей Службе доставки. В&nbsp;случае невозможности доставки Товара{' '}
                {legalName} уведомляет Покупателя об&nbsp;этом при оформлении Заказа. Подробные Правила
                доставки приведены в&nbsp;Интернет-магазине.
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  style={{ margin: 'var(--space-100) 0 0 0' }}
                  color="text-primary"
                >
                  Доставка Товаров курьером и&nbsp;в&nbsp;пункт выдачи/Постамат Службы доставки
                  осуществляется привлеченной {legalName} Службой доставки или силами Продавца.
                </Typography>
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Дату и&nbsp;временной интервал получения Товаров Покупатель выбирает при оформлении Заказа
                (в&nbsp;экранной форме на&nbsp;Сайте или в&nbsp;Мобильном приложении &laquo;Лемана
                ПРО&raquo;, или устно с&nbsp;оператором&nbsp;&mdash; в&nbsp;случае оформления Покупателем
                Заказа по&nbsp;телефону).
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае предоставления Покупателем неверных контактных данных (своих или третьих лиц,
                в&nbsp;том числе предоставление данных, не&nbsp;принадлежащих Покупателю и/или Получателю)
                {legalName} и/или Продавец не&nbsp;несут ответственности за&nbsp;ненадлежащее исполнение
                Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                По&nbsp;факту поступления Товаров в&nbsp;Пункт выдачи заказов, в&nbsp;выбранный Покупателем
                торговый центр (магазин) &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot; или при передаче
                Заказа курьеру Покупателю будет направлено сообщение. Только после получения данного
                сообщения Покупателем Заказ можно забрать в&nbsp;Пункте выдачи заказов, в&nbsp;Постамате или
                в&nbsp;Торговом центре (магазине) &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot;.
                Покупатель обязуется не&nbsp;сообщать третьим лицам (за&nbsp;исключением Получателя)
                сведения, необходимые для получения Товаров.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если при оформлении Заказа в&nbsp;Интернет-магазине не&nbsp;был указан иной срок,
                то&nbsp;Товары можно забрать в&nbsp;Пункте выдачи заказов в&nbsp;течение 7&nbsp;календарных
                дней, а&nbsp;в&nbsp;Торговом центре (магазине) &quot;{legalName}&quot;/&quot;Леруа
                Мерлен&quot; или Постамате&nbsp;&mdash; в&nbsp;течение 3&nbsp;календарных дней со&nbsp;дня
                поступления Товаров в&nbsp;Пункт выдачи заказов или в&nbsp;Торговый центр, соответственно.
                Стоимость доставки и&nbsp;сроки хранения Товаров в&nbsp;Постаматах, Пунктах выдачи заказов
                и&nbsp;Торговых центрах (магазинах) &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot;
                Покупатель может уточнить на&nbsp;Сайте до&nbsp;оформления Заказа. Товар может быть доставлен
                в&nbsp;Постамат или в&nbsp;Пункт выдачи заказов ранее обещанной даты, о&nbsp;чем {legalName}{' '}
                уведомит Покупателя; в&nbsp;таком случае срок, отведенный на&nbsp;получение Товаров,
                отсчитывается со&nbsp;дня фактической доставкиТовара в&nbsp;Постамат или в&nbsp;Пункт выдачи
                заказов.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Доставленные в&nbsp;пункт выдачи заказов, Постамат или в&nbsp;Торговый центр (магазин) &quot;
                {legalName}&quot;/&quot;Леруа Мерлен&quot; Товары Покупатель должен получить в&nbsp;указанный
                в&nbsp;уведомлении срок, а&nbsp;если Покупатель пропустил или не&nbsp;получил уведомление,
                то&nbsp;он&nbsp;сам должен предпринять в&nbsp;разумный срок необходимые и&nbsp;достаточные
                действия для того, чтобы получить от&nbsp;{legalName} информацию о&nbsp;сроке поступления
                Товаров в&nbsp;ПВЗ или в&nbsp;выбранный Покупателем Торговый центр (магазин) &quot;
                {legalName}&quot;/&quot;Леруа Мерлен&quot;. Неявка Получателя за&nbsp;Товарами
                в&nbsp;указанный в&nbsp;уведомлении/сообщенный Покупателю {legalName} срок является
                основанием для отказа Продавца от&nbsp;договора купли-продажи (ст.&nbsp;484&nbsp;ГК РФ)
                и&nbsp;для аннулирования Заказа. Если неполученные Товары были предоплачены, денежные
                средства возвращаются Покупателю в&nbsp;порядке, предусмотренном настоящими Правилами.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Товары были доставлены в&nbsp;установленный срок, но&nbsp;не&nbsp;были получены
                Покупателем по&nbsp;его вине, последующая доставка (если Заказ не&nbsp;расформирован
                и&nbsp;повторная доставка возможна) производится в&nbsp;новые сроки, согласованные с&nbsp;
                {legalName}, после повторной оплаты Покупателем стоимости доставки (при заказе Покупателем
                Товаров из&nbsp;ассортимента {legalName} и&nbsp;выборе Покупателем доставки силами
                транспортной компании из&nbsp;Торгового центра (магазина) &quot;{legalName}&quot;/&quot;Леруа
                Мерлен&quot;).
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.9.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} приложит все усилия для соблюдения сроков доставки, указанных при оформлении
                Заказа, тем не&nbsp;менее, задержки в&nbsp;доставке или доставка раньше намеченного срока
                возможны ввиду непредвиденных обстоятельств, произошедших не&nbsp;по&nbsp;вине {legalName}.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.10.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Товары вручаются Покупателю или Получателю, а&nbsp;в&nbsp;их&nbsp;отсутствие&nbsp;&mdash;
                любому лицу, предъявившему информацию о&nbsp;номере Заказа, либо иное (в&nbsp;том числе
                электронное) подтверждение заключения договора розничной купли-продажи или оформления Заказа
                Покупателем. Товары вручаются лицу, готовому предоставить сведения о&nbsp;Заказе (номер
                Заказа и/или ФИО Получателя, Покупателя), а&nbsp;также оплатить лицу, осуществляющему
                доставку Товаров, стоимость Товаров в&nbsp;полном объеме. Для получения Товаров
                в&nbsp;Постамате Покупатель должен ввести ПИН-код. Лицо, которое ввело ПИН-код или
                предоставило сведения о&nbsp;Заказе, считается уполномоченным Получателем Товара.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.11.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае, если Покупатель передал третьему лицу (другу, родственнику и&nbsp;т.&nbsp;д.)
                номер Заказа (сообщил ПИН-код или предоставил иные сведения о&nbsp;Заказе) и&nbsp;такое лицо
                сообщает данный номер Заказа (ПИН-код, иные сведения) при получении Товаров, это
                расценивается как передача Покупателем такому лицу всех полномочий по&nbsp;получению
                (и&nbsp;оплате, если это применимо) Товаров.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.12.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Стоимость доставки Товаров рассчитывается автоматически. При определении стоимости доставки
                учитываются вес (в&nbsp;том числе объемный вес) Товаров, адрес доставки, протяжённость
                маршрута, скорость, срок доставки, день недели, время суток и&nbsp;способ доставки, выбранный
                Покупателем при оформлении Заказа вид тарифа, проводимые Продавцом (или с&nbsp;его участием)
                в&nbsp;момент оформления Заказа маркетинговые акции. Стоимость доставки указывается
                на&nbsp;последнем этапе оформления Заказа. Доставка только части Заказа не&nbsp;производится;
                в&nbsp;случае утраты/повреждения части Товаров при доставке Заказ будет отменен, денежные
                средства возвращены.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.13.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Во&nbsp;избежание случаев мошенничества, а&nbsp;также для выполнения взятых на&nbsp;себя
                обязательств при вручении предоплаченных Товаров, лицо, осуществляющее доставку Заказа
                Покупателю, вправе затребовать документ, удостоверяющий личность Получателя, а&nbsp;также
                зафиксировать тип и&nbsp;номер предоставленного Получателем документа. {legalName}{' '}
                гарантирует конфиденциальность и&nbsp;защиту персональной информации Получателя.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.14.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При получении Товаров Получатель должен в&nbsp;месте получения Товаров проверить внешний вид
                и&nbsp;упаковку Товаров, количество Товаров в&nbsp;Заказе, комплектность, ассортимент,
                целостность Товаров. Подпись Получателя в&nbsp;документах, подтверждающих получение Товаров
                (в&nbsp;том числе в&nbsp;электронных документах), а&nbsp;равно сообщение Получателем
                одноразового кода означает отсутствие претензий Покупателя к&nbsp;количеству, внешнему виду,
                ассортименту, целостности и&nbsp;комплектности Товара. При обнаружении несоответствий
                вышеуказанных характеристик Товара Заказу Покупатель вправе отказаться от&nbsp;Товара (см.
                Раздел 7&nbsp;ниже). После приемки Товара Получателем Продавец не&nbsp;принимает претензий
                к&nbsp;Товару в&nbsp;отношении вышеуказанных характеристик Товара. Товар считается полученным
                Покупателем в&nbsp;момент его передачи Получателю. Принимая Товар и&nbsp;подписывая документ,
                подтверждающий получение Товаров, или сообщая одноразовый код, Получатель подтверждает
                надлежащее исполнение обязательств Продавцом по&nbsp;Заказу.{' '}
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  style={{ margin: 'var(--space-100) 0 0 0' }}
                  color="text-primary"
                >
                  При получении Товара в&nbsp;Постамате Службы доставки или Торгового центра (магазина)
                  &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot;, Товар считается полученным Получателем
                  в&nbsp;момент автоматического открытия дверцы Постамата после ввода ПИН-кода.
                  В&nbsp;некоторых случаях (в&nbsp;зависимости от&nbsp;модели Постамата) может потребоваться
                  проставление Получателем подписи на&nbsp;экране Постамата, что признается Покупателем
                  и&nbsp;Продавцом аналогом собственноручной подписи. При обнаружении несоответствий
                  вышеуказанных характеристик полученного в&nbsp;Постамате Товара Заказу Покупатель должен
                  незамедлительно сообщить об&nbsp;этом {legalName} через форму обратной связи на&nbsp;Сайте:{' '}
                  <a href="/company/obratnaya-svyaz/" className={link}>
                    https://lemanapro.ru/company/obratnaya-svyaz/
                  </a>{' '}
                  или по&nbsp;телефону:{' '}
                  <a href={phoneNumber} className={cx(link, number)}>
                    8 (800) 700-00-99
                  </a>
                  .
                </Typography>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  style={{ margin: 'var(--space-100) 0 0 0' }}
                  color="text-primary"
                >
                  При получении Заказа в&nbsp;торговом центре (магазине) &quot;{legalName}&quot;/&quot;Леруа
                  Мерлен&quot; либо при выборе Покупателем доставки Товаров из&nbsp;ассортимента {legalName}{' '}
                  транспортной компанией Покупатель вправе отказаться от&nbsp;всех или некоторых
                  из&nbsp;заказанных Товаров, сообщив об&nbsp;этом представителю {legalName}. Для остальных
                  способов передачи Товаров частичный отказ недоступен&nbsp;&mdash; при доставке
                  в&nbsp;Постамат, в&nbsp;Пункт выдачи заказов Службы доставки или курьером Службы доставки.
                </Typography>
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.15.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Риск случайной гибели или случайного повреждения Товара переходит к&nbsp;Покупателю
                с&nbsp;момента передачи Товаров Получателю или ввода им&nbsp;ПИН-кода в&nbsp;Постамате.
                Покупатель и&nbsp;Продавец признают допустимым доказательством передачи Получателю Товара
                сообщение Получателем ПИН-кода (иных сведений о&nbsp;Заказе) курьеру Службы доставки, отметку
                Покупателя (в&nbsp;том числе электронную отметку в&nbsp;электронной накладной или электронном
                маршрутном листе Службы доставки) о&nbsp;получении Товара, видеозапись из&nbsp;пункта выдачи
                заказов, из&nbsp;торгового центра (магазина) &quot;{legalName}&quot;/&quot;Леруа Мерлен&quot;
                и&nbsp;с&nbsp;места установки Постамата.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.16.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Заказ не&nbsp;может быть вручен Покупателю по&nbsp;не&nbsp;зависящим от&nbsp;Продавца
                причинам (Покупателя нет на&nbsp;месте в&nbsp;условленное время, Покупателем неверно указан
                адрес доставки или номер телефона, Покупатель не&nbsp;явился за&nbsp;Заказом в&nbsp;Пункт
                выдачи заказов или не&nbsp;забрал Заказ в&nbsp;установленный срок из&nbsp;Постамата,
                и&nbsp;проч.), то&nbsp;это считается отказом Покупателя от&nbsp;договора купли-продажи
                и&nbsp;является основанием для аннулирования Заказа. Если неполученный Заказ был предоплачен,
                {legalName} возвращает Покупателю уплаченные последним денежные средства (за&nbsp;вычетом
                стоимости доставки) в&nbsp;порядке, предусмотренном настоящими Правилами.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.17
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае недоставки Заказа по&nbsp;вине Продавца, {legalName} возвращает Покупателю
                стоимость предоплаченного Покупателем Заказа и&nbsp;доставки в&nbsp;порядке, предусмотренном
                настоящими Правилами (и&nbsp;после получения подтверждения утраты Заказа от&nbsp;Службы
                доставки, если применимо).
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              6.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Оплата Товара
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Цена Товара указывается в&nbsp;Интернет-Магазине. В&nbsp;силу объективных или субъективных
                причин возможны ошибки при указании цен Товаров (отказ или повреждение&nbsp;&mdash;
                не&nbsp;устраняющееся без вмешательства оператора нарушение работы программного или
                аппаратного обеспечения Продавца или его контрагентов, ошибка человека при внесении данных
                о&nbsp;цене товара в&nbsp;базу данных, опечатка и&nbsp;т.п.) в&nbsp;Интернет-Магазине. При
                возникновении сомнений в&nbsp;отношении указанной в&nbsp;Интернет-Магазине цены Товара,
                Покупатель может уточнить цену через форму обратной связи, размещенную на&nbsp;Сайте
                на&nbsp;странице &laquo;Клиентская поддержка&raquo;&nbsp;
                <a href="/company/obratnaya-svyaz/" className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>
                . В&nbsp;случае обнаружения такой ошибки в&nbsp;отношении цены заказанного Покупателем
                Товара, {legalName} информирует Покупателя об&nbsp;этом. Подтверждение о&nbsp;готовности
                Товара к&nbsp;отгрузке Покупателю не&nbsp;направляется.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Цена Товара в&nbsp;Интернет-Магазине может быть изменена Продавцом. Цена Товаров и&nbsp;общая
                стоимость Заказа указывается на&nbsp;последнем этапе оформления Заказа и&nbsp;действительна
                на&nbsp;момент нажатия кнопки &quot;Оформить заказ&quot;/&quot;Перейти к&nbsp;оплате&quot;.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Способы оплаты Товара указаны в&nbsp;Интернет-Магазине. Согласованным сторонами способом
                оплаты считается способ, выбранный Покупателем из&nbsp;доступных способов оплаты при
                оформлении Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При предоплате Товаров Заказ принимается в&nbsp;работу только после его оплаты Покупателем.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Во&nbsp;избежание случаев различного рода неправомерного использования банковских карт при
                оплате, все Заказы, оформленные в&nbsp;Интернет-Магазине с&nbsp;предоплатой, проверяются{' '}
                {legalName}.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При оплате в&nbsp;Интернет-Магазине Покупателю направляется электронный чек (или ссылка
                на&nbsp;скачивание электронного чека) по&nbsp;адресу электронной почты, указанному
                Покупателем при оформлении Заказа на&nbsp;Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Исполнение Заказа Покупателя обеспечивает одно из&nbsp;подразделений {legalName} или лицо,
                указанное в&nbsp;качестве Продавца на&nbsp;странице Товара в&nbsp;Интернет-Магазине.
                В&nbsp;силу особенности организации торговли дистанционным способом, цена одного
                и&nbsp;того&nbsp;же Товара в&nbsp;разных регионах может отличаться в&nbsp;зависимости
                от&nbsp;климатических, экономических, географических границ, территориальных особенностей
                регионов, и&nbsp;других объективных факторов, связанных в&nbsp;первую очередь,
                с&nbsp;различными затратами Продавца на&nbsp;перевозку, организацию продажи и&nbsp;доставку
                Товара Покупателю.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если в&nbsp;одном Заказе Покупателем собраны Товары разных Продавцов, то&nbsp;оплата Товаров
                каждого Продавца осуществляется отдельно.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              7.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Возврат Товара и денежных средств, уплаченных за Товар.
            </Typography>
          </div>
          <Typography
            variant="s"
            size="body_long"
            as="p"
            className={paragraphWithoutNumber}
            style={{ margin: 'var(--space-200) 0 0 0' }}
            color="text-primary"
          >
            Для возврата Товара Покупателю необходимо привезти Товар в&nbsp;один из&nbsp;Торговых центров
            (магазинов) &laquo;{legalName}&raquo;/&laquo;Леруа Мерлен&raquo;; если&nbsp;же Товар весит более
            5&nbsp;кг или в&nbsp;месте жительства Покупателя нет Торгового центра (магазина) &laquo;
            {legalName}&raquo;/&laquo;ЛЕРУА МЕРЛЕН&raquo;, Покупатель должен обратиться к&nbsp;{legalName}{' '}
            для вызова курьера для возврата Товара. Подробные правила возврата Товаров приведены
            на&nbsp;Сайте в&nbsp;разделе &laquo;Возврат товара&raquo;&nbsp;
            <a href="/service/vozvrat-tovara/" className={link}>
              https://lemanapro.ru/service/vozvrat-tovara/
            </a>
            . Покупатель обязан отдать возврат любому приехавшему за&nbsp;ним лицу, которое назовет номер
            заказа, и&nbsp;подписать Акт приема-передачи.
          </Typography>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат товара надлежащего качества.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель вправе отказаться от&nbsp;Товара надлежащего качества в&nbsp;любое время
                до&nbsp;его получения, а&nbsp;после получения Товара&nbsp;&mdash; в&nbsp;течение 100 дней
                со&nbsp;дня его получения, не&nbsp;считая дня передачи Товара Покупателю. Для
                Покупателей&nbsp;&mdash; участников программы лояльности {legalName} предусмотрен увеличенный
                срок возврата Товара надлежащего качества&nbsp;&mdash;
                подробнее&nbsp;см.&nbsp;в&nbsp;Правилах программы лояльности.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель не&nbsp;вправе отказаться от&nbsp;Товара надлежащего качества, имеющего
                индивидуально-определенные свойства, если указанный Товар может быть использован
                исключительно приобретающим его Покупателем.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат Товара надлежащего качества (в&nbsp;том числе технически сложного товара бытового
                назначения) возможен в&nbsp;случае, если сохранены его потребительские свойства
                и&nbsp;товарный вид, документ, подтверждающий факт и&nbsp;условия покупки указанного Товара
                у&nbsp;Продавца.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат товара ненадлежащего качества
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.2.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель может возвратить Товар ненадлежащего качества Изготовителю или Продавцу
                и&nbsp;потребовать возврата уплаченной денежной суммы в&nbsp;течение гарантийного срока,
                срока годности либо, если такой срок не&nbsp;установлен, в&nbsp;разумный срок,
                не&nbsp;превышающий двух лет со&nbsp;дня передачи Товара Покупателю с&nbsp;учетом положений
                п.6 ст.18 Закона РФ&nbsp;&laquo;О&nbsp;защите прав потребителей&raquo;.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат денежных средств
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При отказе Покупателя от&nbsp;Товара надлежащего качества {legalName} возвращает Покупателю
                уплаченную за&nbsp;Товар денежную сумму, за&nbsp;исключением расходов на&nbsp;доставку
                от&nbsp;Покупателя возвращенного Товара (в&nbsp;размере стоимости доставки, оплаченной
                Покупателем при оформлении Заказа).
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  style={{ margin: 'var(--space-100) 0 0 0' }}
                  color="text-primary"
                >
                  В&nbsp;случае отказа Покупателя от&nbsp;Товара ненадлежащего качества {legalName} вернет
                  Покупателю уплаченную за&nbsp;Товар денежную сумму. По&nbsp;требованию {legalName}{' '}
                  и&nbsp;за&nbsp;его счет Покупатель должен возвратить Товар с&nbsp;недостатками.
                </Typography>
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Денежные средства будут {legalName} возвращены Покупателю в&nbsp;течение 10&nbsp;дней
                с&nbsp;момента получения требования Покупателя.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Расходы на&nbsp;возврат суммы, уплаченной Покупателем за&nbsp;товар ненадлежащего качества,
                несет {legalName}. В&nbsp;случаях отказа Покупателя от&nbsp;товара по&nbsp;иным основаниям,
                расходы на&nbsp;возврат суммы, уплаченной Покупателем за&nbsp;товар, несет Покупатель.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Товар был оплачен наличными деньгами, то&nbsp;возврат денежных средств осуществляется
                на&nbsp;счет Покупателя в&nbsp;банке (на&nbsp;банковскую карту). Для этого Покупателю
                необходимо в&nbsp;заявлении на&nbsp;возврат Товара указать банковские реквизиты своего счета,
                полный почтовый адрес (в&nbsp;том числе индекс), фамилию, имя и&nbsp;отчество получателя.
                Обращаем внимание, что только по&nbsp;номеру карты Покупателя или по&nbsp;номеру телефона
                Покупателя возврат денежных средств произвести невозможно. Покупатель обязан предоставить
                полностью банковские реквизиты.
              </Typography>
            </li>
            <li className={cx(subListItem, dottedListItem)}>
              <div className={dottedListPart}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="span"
                  className={subOrderNumber}
                  color="text-primary"
                >
                  7.3.5.
                </Typography>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  className={subParagraph}
                  color="text-primary"
                >
                  В&nbsp;случае, если Товар был оплачен банковской картой, то&nbsp;возврат денежных средств
                  Покупателю осуществляется на&nbsp;ту&nbsp;же банковскую карту, с&nbsp;которой была
                  произведена оплата. Для возврата денежных средств при оплате картой необходимо:
                </Typography>
              </div>
              <ol className={dottedOrderedList}>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    Позвонить по номеру{' '}
                    <a href={phoneNumber} className={cx(link, number)}>
                      8 (800) 700-00-99
                    </a>
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    Заполнить бланк-заявление на возврат
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    Передать его сотруднику Службы доставки вместе с Товаром
                  </Typography>
                </li>
              </ol>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Денежные средства будут перечислены в&nbsp;течение 10&nbsp;дней с&nbsp;момента получения{' '}
                {legalName} письменного заявления Покупателя. Срок поступления денежных средств
                на&nbsp;банковскую карту Покупателя зависит от&nbsp;условий обслуживания банка Покупателя.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При невозможности возврата денег на&nbsp;карту, которой была произведена оплата, Покупатель
                предоставляет заявление с&nbsp;указанием платежных реквизитов для возврата денег
                и&nbsp;объяснением причин (с&nbsp;подтверждением справкой о&nbsp;закрытии счета и/или иным
                документом из&nbsp;банка), по&nbsp;которым невозможен возврат денег на&nbsp;карту, которой
                была произведена оплата. Возврат денег наличными при оплате банковской картой
                не&nbsp;производится.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Товар был оплачен банковской картой, но&nbsp;Подтверждение о&nbsp;готовности Товара
                к&nbsp;отгрузке не&nbsp;было направлено Покупателю, то&nbsp;захолдированные на&nbsp;счете
                Покупателя (и&nbsp;еще не&nbsp;поступившие на&nbsp;расчетный счет {legalName}) денежные
                средства банк Покупателя расхолдирует на&nbsp;счете Покупателя. В&nbsp;таком случае
                уведомление о&nbsp;возврате денег Покупателю не&nbsp;направляется.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Порядок действий при нарушении Продавцом условия об ассортименте:
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае обнаружения в&nbsp;Заказе Товара, не&nbsp;соответствующего заказанному
                ассортименту (т.н. &laquo;пересорт&raquo;) либо расхождений по&nbsp;количеству Товара,
                Покупатель вправе при получении отказаться от&nbsp;Заказа либо (если в&nbsp;Заказе только
                Товары {legalName}, а&nbsp;способ получения доставка транспортной компанией) от&nbsp;части
                Заказа, сделав об&nbsp;этом отметку в&nbsp;документе, подтверждающем получение Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Денежные средства, оплаченные Покупателем за&nbsp;фактически не&nbsp;переданный Товар,
                подлежат возврату на&nbsp;основании обращения Покупателя с&nbsp;заявлением о&nbsp;возврате
                денежных средств. Возврат уплаченной за&nbsp;товар суммы осуществляется тем способом, которым
                была произведена оплата.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Товар, переданный Покупателю в&nbsp;нарушение условия об&nbsp;ассортименте/количестве,
                подлежит возврату Продавцу. Если Покупатель все&nbsp;же принимает данный Товар, то&nbsp;
                {legalName} вправе потребовать от&nbsp;Покупателя оплаты данного Товара по&nbsp;цене,
                установленной для данного Товара в&nbsp;Интернет-Магазине на&nbsp;момент передачи Товара.
                Если фактически переданный Товар отсутствует в&nbsp;ассортименте Интернет-Магазина
                на&nbsp;момент передачи Товара, данный Товар оплачивается по&nbsp;цене, согласованной с&nbsp;
                {legalName}.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              8.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Интеллектуальные права
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                8.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Интеллектуальные права на&nbsp;материалы, представленные на&nbsp;Сайте
                и&nbsp;в&nbsp;Мобильном приложении &laquo;{legalName}&raquo;, включая все его содержимое,
                информацию, фотографии, иллюстрации, рисунки, наименования, товарные знаки, коммерческие
                обозначения и&nbsp;логотипы, принадлежат ООО &laquo;ЛЕ&nbsp;МОНЛИД&raquo; и/или его
                поставщикам, его партнерам и&nbsp;производителям Товаров, и&nbsp;охраняются законом.
                Материалы предназначены исключительно для личного, некоммерческого использования. Содержание
                Сайта, как описано выше, не&nbsp;может быть изменено, скопировано, воспроизведено, или
                выставлено на&nbsp;продажу, сдано в&nbsp;аренду, дополнено или использовано любым другим
                способом без предварительного письменного разрешения от&nbsp;ООО
                &laquo;ЛЕ&nbsp;МОНЛИД&raquo;.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              9.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Гарантии и ответственность
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего
                использования Товаров, заказанных в Интернет-Магазине.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} не несет ответственности за содержание и функционирование внешних сайтов, ссылки
                на которые могут содержаться в Интернет-Магазине.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} вправе без предварительного уведомления Покупателя переуступать либо каким-либо
                иным способом передавать свои права и&nbsp;обязанности, вытекающие из&nbsp;его отношений
                с&nbsp;Покупателем, третьим лицам.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Информация о&nbsp;форме и&nbsp;способах направления претензий. Свои вопросы и&nbsp;пожелания
                Покупатель может направить в&nbsp;Интернет-Магазине через форму обратной связи, размещенную
                в&nbsp;Интернет-Магазине на&nbsp;странице &laquo;Клиентская поддержка&raquo;
                по&nbsp;адресу&nbsp;
                <a href="/company/obratnaya-svyaz/" className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>
                , указав соответствующую Тему обращения, или по телефону{' '}
                <a href={phoneNumber} className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                . В случае возникновения претензий со стороны Покупателя, он может обратиться к Продавцу
                через ту же форму обратной связи, указав Тему обращения – «Претензия» или по телефону{' '}
                <a href={phoneNumber} className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                . Все возникающее споры стороны будут рассматриваться в порядке, предусмотренном действующим
                законодательством РФ.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Признание судом недействительности какого-либо положения настоящих Правил не влечет за собой
                недействительность остальных положений.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              10.
            </Typography>
            <Typography variant="m" size="body_accent" color="text-primary" className={paragraphHeading}>
              Конфиденциальность и защита персональной информации
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Предоставляя свои персональные данные при оформлении Заказа или при создании Личного
                кабинета, Покупатель соглашается на&nbsp;их&nbsp;обработку {legalName}.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} вправе передавать персональные данные Покупателя (Получателя) третьим лицам,
                включая Продавцов и&nbsp;Службы доставки, для целей исполнения обязательств Лемана
                ПРО/Продавцов перед Покупателем. Подробно описание принципов и&nbsp;подходов {legalName}{' '}
                в&nbsp;отношении обработки и&nbsp;обеспечения безопасности персональных данных Покупателей
                приведено в&nbsp;Политике обработки персональных данных в&nbsp;ООО
                &laquo;ЛЕ&nbsp;МОНЛИД&raquo;, размещенной на&nbsp;Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Покупатель выразил на&nbsp;то&nbsp;свое особое согласие, то&nbsp;{legalName} вправе
                направлять ему сообщения информационного характера. Если Покупатель не&nbsp;желает получать
                рассылки от&nbsp;{legalName}, он&nbsp;должен отказаться от&nbsp;подписки путём
                &laquo;клика&raquo; по&nbsp;соответствующей ссылке в&nbsp;направляемом {legalName} письме.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} использует технологию &laquo;cookies&raquo;. Подробная информация
                о&nbsp;технологии &laquo;cookies&raquo; и&nbsp;её&nbsp;использовании {legalName} содержится
                в&nbsp;Предупреждении об&nbsp;использовании файлов cookies на&nbsp;Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} получает информацию об&nbsp;IP-адресе посетителя Сайта. Данная информация
                не&nbsp;используется для установления личности посетителя.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} не&nbsp;несет ответственности за&nbsp;сведения, сообщенные Покупателем
                на&nbsp;Сайте в&nbsp;общедоступной форме.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                {legalName} не&nbsp;несет ответственности за&nbsp;убытки, которые Покупатель может понести
                в&nbsp;результате того, что его логин и&nbsp;пароль стали известны третьему лицу.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае возникновения у&nbsp;{legalName} подозрений относительно использования Личного
                кабинета, логина, пароля, адреса электронной почты Покупателя третьим лицом или вредоносным
                программным обеспечением, {legalName} вправе в&nbsp;одностороннем порядке изменить пароль
                Покупателя с&nbsp;уведомлением последнего на&nbsp;указанный при регистрации адрес электронной
                почты.
              </Typography>
            </li>
          </ol>
        </li>
      </OrderedList>
    </div>
  </>
));
