import * as React from 'react';
import { SellingRules } from 'pages/selling-rules/selling-rules';
import { Contacts } from 'pages/contacts';
import { AboutUs } from 'pages/about-us/about-us';
import { RefundPage } from 'pages/refund-page';
import { REGION_STUB_PATH, REGIONS_PATH_NAME } from 'common/constants';
import { PageError } from 'pages/error';
import { RegionStubPage } from 'pages/region-stub-page/region-stub-page';
import { useSelector } from 'react-redux';
import { isDomainKZ } from 'features/page-model/ducks/get-model';
import { PravilaProvedeniyaAkciiShokCena } from '../pages/shock-cena';
import { Rebranding } from '../pages/rebranding/rebranding';
import { RebrandingKz } from '../pages/rebranding-kz/rebranding-kz';

const RebrandingPage: React.FC = () => {
  const isKZ = useSelector(isDomainKZ);
  return isKZ ? <RebrandingKz /> : <Rebranding />;
};

export type Route = {
  key: React.Key;
  component?: React.ElementType;
  path: string | string[];
  exact?: boolean;
  strict?: boolean;
  routes?: Route[];
};

export type RootRoute = Route & {
  routes: Route[];
};

export const routes: RootRoute[] = [
  {
    key: 'RegionStub',
    component: RegionStubPage,
    path: [`/${REGIONS_PATH_NAME}/`, REGION_STUB_PATH],
    exact: true,
    strict: false,
    routes: [],
  },
  {
    key: 'Pravila',
    component: SellingRules,
    path: [`/pravila/`],
    exact: true,
    strict: false,
    routes: [],
  },
  {
    key: 'Contacts',
    component: Contacts,
    path: ['/contacty/'],
    exact: true,
    strict: false,
    routes: [],
  },
  {
    key: 'Vozvrat-tovara',
    component: RefundPage,
    path: ['/service/vozvrat-tovara/'],
    exact: true,
    strict: false,
    routes: [],
  },
  {
    key: 'AboutUs',
    component: AboutUs,
    path: ['/about/'],
    exact: true,
    strict: false,
    routes: [],
  },
  {
    key: 'Rebranding',
    component: RebrandingPage,
    path: ['/about/rebrending/'],
    exact: true,
    strict: false,
    routes: [],
  },
  {
    key: 'PravilaProvedeniyaAkciiShokCena',
    component: PravilaProvedeniyaAkciiShokCena,
    path: '/pravila-provedeniya-akcii-shok-cena/',
    exact: true,
    strict: false,
    routes: [],
  },
];
export const errorRoutes: RootRoute[] = [
  {
    key: 'Error',
    component: PageError,
    path: '',
    exact: false,
    strict: false,
    routes: [],
  },
];
