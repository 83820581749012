import { PhoneLinkBase } from 'common/components/phone-link-base';
import { createMainUrl } from 'common/constants';
import { getStubRegionDeliveryFrom } from 'features/stub-region/ducks/get-stub-region-data';
import { Typography } from 'fronton-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { saleOfProjectsClass, saleOfProjectsHeaderClass } from './styles';

export const QA_LOCATOR = 'sale-of-projects';

export const SaleOfProjects = () => {
  const regionData = useSelector(getStubRegionDeliveryFrom);

  const mainUrl = createMainUrl(regionData?.regionLink || '');
  const uslugiUrl = mainUrl + 'uslugi/';
  const bathroomUrl = mainUrl + 'uslugi/proektirovanie-i-dizayn/sozdayte-vannuyu-komnatu-svoey-mechty/';
  const kitchenUrl = mainUrl + 'proekt-kuhni/';
  const storageSystemsUrl = mainUrl + 'uslugi/proektirovanie-i-dizayn/proektirovanie-sistem-hraneniya/';

  return (
    <div className={saleOfProjectsClass} data-qa={QA_LOCATOR}>
      <Typography variant="l" size="body_accent" as="h2" className={saleOfProjectsHeaderClass}>
        Продажа проектов и решений
      </Typography>
      <Typography variant="m" size="body_long">
        Мы предлагаем комплексные решения задач по ремонту и обустройству: создадим для вас бесплатно
        дизайн-проект <a href={bathroomUrl}>ванной</a>, <a href={kitchenUrl}>кухни</a> или{' '}
        <a href={storageSystemsUrl}>системы хранения</a> для любого помещения. Записаться на проектирование
        вы можете по телефону <PhoneLinkBase /> или на нашем <a href={uslugiUrl}>сайте</a>. Для вас доступно
        онлайн-проектирование со специалистом или проектирование в нашем магазине.
      </Typography>
    </div>
  );
};
