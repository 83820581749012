import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsUtils, patchUrlByLocale } from 'utils';
import { Card, Cards, Icon } from 'common/components';

type CardContent = {
  icon: Icon;
  text: ReactElement;
  href: string;
  linkText: string;
};

export const CardsSection: React.FC = () => {
  const { t } = useTranslation();
  const legalName = t('legalName');

  const cardsContent: CardContent[] = [
    {
      icon: 'box',
      text: (
        <>
          <span>Сотрудничаем</span> с&nbsp;поставщиками
        </>
      ),
      href: patchUrlByLocale('/postavshchikam/'),
      linkText: 'Стать поставщиком',
    },
    {
      icon: 'lmpro',
      text: (
        <>
          <span>Предлагаем</span> товары и услуги для профессионалов
        </>
      ),
      href: patchUrlByLocale('/biznes/'),
      linkText: 'Стать клиентом',
    },
    {
      icon: 'platform',
      text: (
        <>
          <span>Cтроим</span> технологичную компанию-платформу
        </>
      ),
      href: 'https://lemanatech.ru/',
      linkText: 'Узнать больше',
    },
    {
      icon: 'care',
      text: (
        <>
          <span>Заботимся</span> об&nbsp;окружающей среде и&nbsp;людях
        </>
      ),
      href: patchUrlByLocale('/ustoychivoe-razvitie/'),
      linkText: 'Читать подробнее',
    },
  ];

  const gaClickButton = useCallback(
    (elText: string) => {
      analyticsUtils.sendEvent('GA:clickButton', {
        elementType: 'button',
        elementBlock: 'about', // блок, в котором расположен элемент
        elementName: `О компании ${legalName}`, // название блока (заголовок)
        elementText: elText, // текст гиперссылки (зеленым)
      });
    },
    [legalName],
  );

  return (
    <Cards>
      {cardsContent.map(({ icon, text, href, linkText }) => (
        <Card
          key={href}
          icon={icon}
          href={patchUrlByLocale(href)}
          onClick={() => gaClickButton(linkText)}
          linkText={linkText}
        >
          {text}
        </Card>
      ))}
    </Cards>
  );
};
