import React, { FC } from 'react';
import { LogoLemanaPro, Typography } from 'fronton-react';
import { Helmet } from '@okapi/helmet-react';
import { useSelector } from 'react-redux';
import { getLogoImage } from 'features/page-model/ducks/get-model';
import { container, textRegion } from './styles';

export const DATA_QA_STUB_REGION_HEADER_CONTAINER = 'stub-region-header-container';
export const DATA_QA_STUB_REGION_HEADER_TITLE = 'stub-region-header-title';
export const DATA_QA_STUB_REGION_HEADER_META = 'stub-region-header-meta';

type Props = {
  region: string;
  legalName: string;
  children?: React.ReactNode;
};

export const Header: FC<Props> = ({ region, legalName, children }) => {
  const logoImage = useSelector(getLogoImage);
  return (
    <div className={container} data-qa={DATA_QA_STUB_REGION_HEADER_CONTAINER}>
      <Helmet>
        <meta property="og:image" content={logoImage} data-qa={DATA_QA_STUB_REGION_HEADER_META} />
      </Helmet>
      <a href="/">
        <div className="visually-hidden" itemProp="name">
          {legalName}
        </div>
        <LogoLemanaPro />
      </a>
      <Typography
        size="body_accent"
        variant="s"
        as="h1"
        className={textRegion}
        data-qa={DATA_QA_STUB_REGION_HEADER_TITLE}
      >
        {legalName} {region}
      </Typography>
      {children}
    </div>
  );
};
