import React from 'react';
import { Typography } from 'fronton-react';
import { PhoneLinkBase } from 'common/components/phone-link-base';
import { listIntroductionClass, listIntroductionItemClass } from './styles';

export const QA_LOCATOR = 'delivery-list-introduction';

type IntroductionProps = {
  url?: string;
  deliveryNameGenitive?: string;
};

export const ListIntroduction = (props: IntroductionProps) => {
  return (
    <div className={listIntroductionClass} data-qa={QA_LOCATOR}>
      <Typography variant="l" size="body_accent">
        В вашем городе нет магазина Лемана ПРО, но вы можете:
      </Typography>
      <ul>
        <li>
          <Typography variant="m" size="body_long" className={listIntroductionItemClass}>
            {'Cделать заказ на доставку на '}
            <a href={props.url}>сайте</a>
          </Typography>
        </li>
        <li>
          <Typography variant="m" size="body_long" className={listIntroductionItemClass}>
            {`Cделать заказ и бесплатно забрать его самовывозом из магазина в ${props.deliveryNameGenitive}`}
          </Typography>
        </li>
        <li>
          <Typography variant="m" size="body_long" className={listIntroductionItemClass}>
            Позвонить в наш контактный центр <PhoneLinkBase />, мы ответим на все ваши вопросы и поможем
            оформить заказ
          </Typography>
        </li>
      </ul>
    </div>
  );
};
