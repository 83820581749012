import React, { MutableRefObject, useRef } from 'react';
import { Layout, Typography } from 'fronton-react';
import { useEffectOnce } from 'common';
import { carouselScroll } from 'utils/carousel-scroll';
import { SquaredBorderWrapper } from 'common/icons';
import { HistoryItem } from '../history-item';
import { HistoryScroll, HistoryWrapper } from './styles';
import { HistoryCarouselItem } from './types';
import { useTranslation } from 'react-i18next';

export type Props = {
  items: HistoryCarouselItem[];
};

export const HistoryCarousel: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();
  const slider = useRef<HTMLDivElement | null>(null);
  let pos = { left: 0, x: 0 };

  useEffectOnce(() => {
    carouselScroll(pos, slider as MutableRefObject<HTMLDivElement>);
  });

  // Add the event listeners
  return (
    <>
      <Layout>
        <Typography variant="h1" color="text-primary" as="h2">
          {t('rebrandingKZ.historyCarousel.historyLemanaPRO')}
        </Typography>
      </Layout>
      <HistoryWrapper ref={slider}>
        <SquaredBorderWrapper />
        <HistoryScroll>
          {items.map((item) => (
            <HistoryItem
              key={item.imgUrl}
              imgUrl={item.imgUrl}
              years={item.years}
              description={item.description}
            />
          ))}
        </HistoryScroll>
        <SquaredBorderWrapper />
      </HistoryWrapper>
    </>
  );
};
