import React from 'react';
import { noteText } from './styles';
import { Typography } from 'fronton-react';
import { useBreakpoints } from 'common/hooks/use-breakpoints';

export const DATA_QA_MAIN_INFO_WITH_DELIVERY_NOTE = 'main-info-with-delivery-note';

export const getNote = (regionNameGenitive: string, regionName: string, legalName: string) =>
  `В\u00A0вашем городе нет магазина ${legalName}, но\u00A0вы\u00A0можете заказать товары и\u00A0спроектировать
   комнату на\u00A0сайте ${regionNameGenitive} с\u00A0доставкой в\u00A0${regionName}
   курьером или в\u00A0пункты выдачи вашего города.`;

type IntroductionProps = {
  regionNameGenitive: string;
  regionName: string;
  legalName: string;
};

export const DefaultIntroduction = (props: IntroductionProps) => {
  const { mobile, smMobile } = useBreakpoints() || {};
  const isMobile = mobile || smMobile;
  const textVariant = isMobile ? 'm' : 'l';

  return (
    <Typography
      variant={textVariant}
      size="body_long"
      className={noteText}
      data-qa={DATA_QA_MAIN_INFO_WITH_DELIVERY_NOTE}
    >
      {getNote(props.regionNameGenitive, props.regionName, props.legalName)}
    </Typography>
  );
};
