import React from 'react';

const DATA_QA = 'data-ga-region-stub-image';

export const NoStoreIcon = () => (
  <svg
    width="289"
    height="191"
    viewBox="0 0 289 191"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-qa={DATA_QA}
  >
    <mask id="path-1-inside-1_9_70348" fill="white">
      <path d="M114.566 53.4788C110.278 37.4748 119.775 21.0247 135.779 16.7364C151.783 12.4482 168.233 21.9456 172.521 37.9496L114.566 53.4788Z" />
    </mask>
    <path
      d="M114.566 53.4788C110.278 37.4748 119.775 21.0247 135.779 16.7364C151.783 12.4482 168.233 21.9456 172.521 37.9496L114.566 53.4788Z"
      fill="white"
    />
    <path
      d="M171.745 35.0518L113.789 50.581L115.342 56.3765L173.298 40.8474L171.745 35.0518ZM136.556 19.6342C150.959 15.7748 165.764 24.3225 169.624 38.7261L175.419 37.1732C170.702 19.5688 152.607 9.12155 135.003 13.8386L136.556 19.6342ZM135.003 13.8386C117.398 18.5557 106.951 36.6508 111.668 54.2552L117.464 52.7023C113.604 38.2987 122.152 23.4936 136.556 19.6342L135.003 13.8386Z"
      fill="#21282B"
      mask="url(#path-1-inside-1_9_70348)"
    />
    <path
      d="M94.2094 77.916L114.757 50.8061C128.761 34.9047 150.941 28.9616 171.019 35.7309L202.369 48.9347C204.219 49.7138 205.605 51.3039 206.124 53.2428L206.391 54.2384C207.32 57.7059 205.262 61.2701 201.795 62.1992L101.339 89.1164C97.8711 90.0455 94.3069 87.9877 93.3778 84.5202L93.1111 83.5246C92.5915 81.5857 92.9969 79.5156 94.2094 77.916Z"
      fill="#B8CAC4"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M206.974 56.4117C207.69 59.0857 207.063 62.1412 205.008 65.4814C202.951 68.8254 199.533 72.3279 194.942 75.7631C185.765 82.6281 172.157 89.0344 156.29 93.2859C140.424 97.5375 125.436 98.7934 114.056 97.4362C108.362 96.7571 103.651 95.4329 100.197 93.5655C96.7476 91.7002 94.6769 89.3676 93.9604 86.6935C93.2438 84.0195 93.8708 80.964 95.9257 77.6238C97.9827 74.2798 101.401 70.7774 105.993 67.3421C115.169 60.4771 128.777 54.0708 144.644 49.8193C160.51 45.5678 175.498 44.3118 186.878 45.669C192.572 46.3481 197.283 47.6723 200.737 49.5397C204.186 51.4051 206.257 53.7377 206.974 56.4117Z"
      fill="#B8CAC4"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M180.912 78.924C181.019 79.321 181.111 80.0442 181.176 81.0043C181.238 81.9332 181.269 82.9859 181.281 83.9858C181.294 84.9742 181.288 85.8979 181.28 86.5792L131.037 99.5443C130.777 99.1975 130.448 98.7365 130.095 98.1851C129.252 96.8677 128.304 95.0899 127.786 93.159C127.704 92.8515 127.775 92.1487 128.85 90.9511C129.873 89.8097 131.554 88.5193 133.837 87.1782C138.386 84.5074 145.024 81.811 152.667 79.763C160.31 77.7149 167.407 76.7311 172.682 76.77C175.33 76.7895 177.43 77.0668 178.888 77.5434C180.417 78.0435 180.83 78.6164 180.912 78.924Z"
      fill="#B8CAC4"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M181.251 87.9152C181.346 88.2714 181.266 88.9765 180.307 90.12C179.381 91.2241 177.845 92.4693 175.741 93.7589C171.551 96.3274 165.405 98.8904 158.302 100.794C151.199 102.697 144.595 103.55 139.682 103.421C137.215 103.356 135.263 103.046 133.909 102.552C132.506 102.042 132.084 101.471 131.989 101.115C131.893 100.759 131.973 100.054 132.933 98.9101C133.859 97.806 135.394 96.5608 137.498 95.2712C141.689 92.7027 147.835 90.1397 154.937 88.2365C162.04 86.3334 168.644 85.48 173.558 85.6091C176.025 85.6739 177.977 85.9845 179.331 86.4777C180.733 86.9884 181.155 87.559 181.251 87.9152Z"
      fill="#FDC300"
      stroke="#21282B"
      strokeWidth="3"
    />
    <g clipPath="url(#clip0_9_70348)">
      <g clipPath="url(#clip1_9_70348)">
        <path d="M212.5 180L177.5 180L177.5 71L212.5 180Z" fill="#FDC300" />
        <rect
          width="109"
          height="47"
          transform="matrix(-1.19249e-08 -1 -1 1.19249e-08 177.5 180)"
          fill="#FDC300"
        />
      </g>
    </g>
    <path
      d="M180.912 78.924C181.019 79.321 181.111 80.0442 181.176 81.0043C181.238 81.9332 181.269 82.9859 181.281 83.9858C181.294 84.9742 181.288 85.8979 181.28 86.5792L131.037 99.5443C130.777 99.1975 130.448 98.7365 130.095 98.1851C129.252 96.8677 128.304 95.0899 127.786 93.159C127.704 92.8515 127.775 92.1487 128.85 90.9511C129.873 89.8097 131.554 88.5193 133.837 87.1782C138.386 84.5074 145.024 81.811 152.667 79.763C160.31 77.7149 167.407 76.7311 172.682 76.77C175.33 76.7895 177.43 77.0668 178.888 77.5434C180.417 78.0435 180.83 78.6164 180.912 78.924Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M176.463 84.3137C178.464 84.5613 180.068 85.0198 181.16 85.6863L180.378 86.9665C180.98 87.3339 181.185 87.6707 181.251 87.9152C181.316 88.1596 181.307 88.5541 180.969 89.1732L182.286 89.891C181.674 91.014 180.514 92.2132 178.905 93.4282L178.001 92.2311C176.908 93.056 175.574 93.904 174.027 94.7495L174.747 96.0657C173.404 96.7993 171.922 97.5225 170.322 98.222L169.721 96.8477C168.317 97.4617 166.817 98.0584 165.236 98.6277L165.744 100.039C164.247 100.578 162.682 101.092 161.062 101.573L160.635 100.135C159.87 100.362 159.092 100.582 158.302 100.794C157.513 101.005 156.729 101.204 155.952 101.39L156.302 102.849C154.658 103.242 153.046 103.579 151.48 103.861L151.214 102.385C149.561 102.682 147.963 102.916 146.44 103.086L146.606 104.577C144.871 104.771 143.226 104.886 141.697 104.921L141.662 103.422C139.899 103.463 138.32 103.396 136.961 103.228L136.777 104.716C134.776 104.469 133.171 104.01 132.08 103.344L132.862 102.064C132.26 101.696 132.054 101.359 131.989 101.115C131.923 100.871 131.933 100.476 132.27 99.8569L130.953 99.1391C131.565 98.0161 132.725 96.8169 134.335 95.6019L135.238 96.799C136.331 95.9741 137.665 95.1261 139.212 94.2806L138.493 92.9644C139.835 92.2308 141.317 91.5076 142.917 90.8081L143.518 92.1824C144.922 91.5684 146.423 90.9717 148.004 90.4024L147.496 88.9911C148.992 88.4522 150.557 87.9382 152.177 87.4572L152.604 88.8952C153.37 88.6679 154.148 88.4481 154.937 88.2365C155.727 88.025 156.511 87.8263 157.287 87.6404L156.938 86.1816C158.582 85.7881 160.194 85.4507 161.76 85.1691L162.025 86.6454C163.679 86.348 165.277 86.1145 166.8 85.9441L166.633 84.4534C168.368 84.2593 170.014 84.1446 171.543 84.1087L171.578 85.6083C173.341 85.5669 174.92 85.6343 176.279 85.8024L176.463 84.3137Z"
      fill="#FDC300"
      stroke="#21282B"
      strokeWidth="3"
      strokeDasharray="5 5"
    />
    <mask id="path-11-inside-2_9_70348" fill="white">
      <rect x="51.5" y="135" width="48" height="48" rx="2" />
    </mask>
    <rect x="51.5" y="135" width="48" height="48" rx="2" fill="white" />
    <rect
      x="51.5"
      y="135"
      width="48"
      height="48"
      rx="2"
      stroke="#21282B"
      strokeWidth="6"
      strokeLinejoin="round"
      mask="url(#path-11-inside-2_9_70348)"
    />
    <path
      d="M69 136.5H82V149C82 149.276 81.7761 149.5 81.5 149.5H69.5C69.2239 149.5 69 149.276 69 149V136.5Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M36.5 181.5H252.5"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_9_70348">
        <rect width="82" height="93" fill="white" transform="translate(130.5 87)" />
      </clipPath>
      <clipPath id="clip1_9_70348">
        <rect
          width="109"
          height="82"
          fill="white"
          transform="matrix(-1.19249e-08 -1 -1 1.19249e-08 212.5 180)"
        />
      </clipPath>
    </defs>
  </svg>
);
