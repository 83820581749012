import { createSelector } from 'reselect';
import { createFeatureSelector } from 'common/create-feature-selector';
import { RootState, Dispatch } from 'store';
import { getDomain, getHostname, getRegion } from 'utils';
import { getQaCookies } from 'utils/get-qa-cookies';
import { FetchModelProps } from 'features/page-model/types';
import { unslashUrl } from 'utils/url';
import {
  REGION_STUB_PATH,
  REGIONS_PATH_NAME,
  MOBILE_VIEW_SEARCH_PARAM,
  MOBILE_VIEW_SEARCH_PARAM_LEGACY,
} from 'common/constants';
import { getLangCookie } from 'utils/get-lang-cookie';
import { fetchModelThunk } from './page-model-slice';
import { GetModelParams } from './api';
import { getEnv } from 'common/selectors';
import { isDomainValid, KZ_DOMAINS } from 'utils/get-region';

export const rootSelector = createFeatureSelector('pageModel');
export const getModelJson = createSelector(rootSelector, (s) => s.data);

export const getBreadcrumbs = createSelector(getModelJson, (m) => m?.breadcrumbs);
export const getSeoTemplates = createSelector(getModelJson, (m) => m?.seoProperties);
export const getMetaRobotsContent = createSelector(
  getModelJson,
  (m) => m?.seoProperties?.metaRobotsContent || [],
);
export const getRegionId = createSelector(getModelJson, (m) => m?.properties.regionId);
export const getRegions = createSelector(getModelJson, (m) => m?.regions);
export const getTitle = createSelector(getModelJson, (m) => m?.seoProperties?.h1);
export const getTextContent = createSelector(getModelJson, (m) => m?.text ?? '');
export const getLogoImage = createSelector(getModelJson, (m) => m?.header?.logoImage ?? '');
export const getExperiments = createSelector(getModelJson, (m) => m?.experiments || []);
export const createIsExperimentExist = (experimentInternalID: string) =>
  createSelector(
    getExperiments,
    (experiments) => experiments?.some(({ internalId }) => internalId === experimentInternalID) ?? false,
  );

export const getPathname = createSelector(
  ({ router }: RootState) => router.path,
  (s) => s,
);
export const getHost = createSelector(
  ({ host }: RootState) => host,
  (s) => s,
);
export const getSearch = createSelector(
  ({ router }: RootState) => router.search,
  (s) => s,
);
export const getPathnameWithSearch = createSelector(
  getPathname,
  getSearch,
  (pathname, search) => pathname + (search ?? ''),
);
export const getIsMobileView = createSelector(
  getSearch,
  (search) =>
    search?.includes(MOBILE_VIEW_SEARCH_PARAM) || search?.includes(MOBILE_VIEW_SEARCH_PARAM_LEGACY),
);
export const getPageUrl = createSelector(getHost, getPathname, (host, pathName) => {
  const protocol = host?.includes('localhost') ? 'http://' : 'https://';
  return protocol + host + pathName;
});

export const getRegionFromHost = createSelector(getHost, (host) => {
  const hostname = getHostname(host || '');
  const domain = getDomain(hostname);
  return getRegion(hostname, domain);
});

export const isDomainKZ = createSelector(getHost, (host) => {
  const hostname = getHostname(host || '');
  const domain = getDomain(hostname);
  return isDomainValid(domain, KZ_DOMAINS);
});

export const fetchModel = (customPath?: string) => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const qaCookie = getQaCookies(state.cookie);
  const lang = getLangCookie(state.cookie);
  const pathname = (customPath ?? state.router?.path) || '';
  const isRegionStubPage = unslashUrl(pathname) === REGIONS_PATH_NAME || pathname === REGION_STUB_PATH;
  const region = isRegionStubPage ? '' : getRegionFromHost(state);
  const shouldUseFallback = ['404', '500'].includes(unslashUrl(pathname)) || isRegionStubPage;
  const { ORCHESTRATOR_HOST = '', API_KEY = '' } = getEnv(state);
  const params: FetchModelProps = {
    pathname: shouldUseFallback ? '/' : pathname,
    region,
    lang,
  };
  const options: GetModelParams = {
    host: ORCHESTRATOR_HOST,
    params,
    config: {
      headers: {
        'x-api-key': API_KEY,
      },
    },
  };

  if (qaCookie) {
    options.config = { ...options.config, headers: { ...options.config?.headers, cookie: qaCookie } };
  }

  return dispatch(fetchModelThunk(options));
};
