import Cookies from 'universal-cookie';
import { getHostname } from './get-hostname';
import { getDomain } from './get-domain';
import { getRegion } from './get-region';
import { getDatePlusYear } from './date-time-helpers';

export const setUserRegionCookie = (host: string | null) => {
  const cookies = new Cookies();
  const userRegionCookie = '_userRegion';
  const currentCookieValue = cookies.get(userRegionCookie);
  const hostname = getHostname(host || '');
  const domain = getDomain(hostname);
  const region = getRegion(hostname, domain);

  if (!currentCookieValue || currentCookieValue !== region) {
    cookies.set(userRegionCookie, region, { path: '/', expires: getDatePlusYear(), domain: `.${domain}` });
  }
};
