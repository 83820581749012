/* eslint-disable max-lines */
import React from 'react';

export const DATA_QA_REGION_STUB_IMAGE = 'data-ga-region-stub-image';

export const StoreIcon = () => (
  <svg
    width="289"
    height="180"
    viewBox="0 0 289 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-qa={DATA_QA_REGION_STUB_IMAGE}
  >
    <g clipPath="url(#clip0_9_68854)">
      <path d="M283.5 45L257.5 45L257.5 109L283.5 45Z" fill="#FDC300" />
      <rect x="257.5" y="45" width="64" height="222" transform="rotate(90 257.5 45)" fill="#FDC300" />
    </g>
    <rect
      x="-1.5"
      y="1.5"
      width="45"
      height="85"
      rx="2.5"
      transform="matrix(-1 0 0 1 156.5 29)"
      fill="#B8CAC4"
      stroke="#21282B"
      strokeWidth="3"
    />
    <rect
      x="-1.5"
      y="1.5"
      width="85"
      height="85"
      rx="2.5"
      transform="matrix(-1 0 0 1 241.5 29)"
      fill="#B8CAC4"
      stroke="#21282B"
      strokeWidth="3"
    />
    <rect
      x="-1.5"
      y="1.5"
      width="130"
      height="85"
      rx="2.5"
      transform="matrix(-1 0 0 1 241.5 29)"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M178.071 98.6975L173.5 93.8572L168.928 98.6975V104.143H178.071V98.6975Z"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M210 31C210 27.4101 207.09 24.5 203.5 24.5H137.5C136.119 24.5 135 25.6193 135 27V30.5H186.5C187.881 30.5 189 31.6193 189 33V51C189 52.3807 190.119 53.5 191.5 53.5H207.5C208.881 53.5 210 52.3807 210 51V31Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <rect width="32" height="32" rx="16" transform="matrix(-1 0 0 1 89.5 123)" fill="white" />
    <circle
      cx="16"
      cy="16"
      r="14.5"
      transform="matrix(-1 0 0 1 89.5 123)"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M229 116H149V138H222.5C226.09 138 229 135.09 229 131.5V116Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M52 136.5H50.5V135V123V115V114.07C50.5 111.723 51.3687 109.459 52.9387 107.715L59.6675 100.238L71.3489 63.2472C71.6774 62.2071 72.6422 61.5 73.7329 61.5H145.5C147.433 61.5 149 63.067 149 65V118V119.5H147.5H123.732L100.373 136.22L99.9815 136.5H99.5H52Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M60.5 99.5H111.705C115.398 99.5 118.612 96.9713 119.48 93.3812L124.604 72.2055C125.06 70.3176 123.63 68.5 121.688 68.5H72.9578C71.2001 68.5 69.6484 69.6474 69.1334 71.328L60.5 99.5Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <line
      x1="1.5"
      y1="-1.5"
      x2="11.3062"
      y2="-1.5"
      transform="matrix(-0.624695 -0.780869 -0.780869 0.624695 102.5 87)"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1.5"
      y1="-1.5"
      x2="6.31"
      y2="-1.5"
      transform="matrix(-0.624695 -0.780869 -0.780869 0.624695 79.5 91.0986)"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.382 100.5L100.829 100H101.5H104.057C106.924 100 109.452 98.1217 110.28 95.3768L117.206 72.4119L118.642 72.845L117.206 72.4119C117.906 70.0894 120.046 68.5 122.472 68.5H147.5H149V70V135V136.5H147.5H134.25H120.5H93H91.5V135V114.057C91.5 111.719 92.3617 109.464 93.9202 107.722L100.382 100.5Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M116.5 100H141.5V76H124L116.5 100Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M110 133.5H50.5C47.4624 133.5 45 135.962 45 139C45 142.038 47.4624 144.5 50.5 144.5H103.5C107.09 144.5 110 141.59 110 138V133.5Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M139 133.5H149.5C152.538 133.5 155 135.962 155 139C155 142.038 152.538 144.5 149.5 144.5H145.5C141.91 144.5 139 141.59 139 138V133.5Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <rect
      x="1.5"
      y="-1.5"
      width="46"
      height="17"
      rx="6.5"
      transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 49.5 132)"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M63.06 118.706C62.911 117.448 61.8443 116.5 60.5773 116.5H52C51.1716 116.5 50.5 117.172 50.5 118V132C50.5 132.828 51.1716 133.5 52 133.5H61.9984C63.4956 133.5 64.6571 132.193 64.481 130.706L63.06 118.706Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M82.9278 130.51C82.6184 132.057 83.8016 133.5 85.3792 133.5L96.5 133.5C97.8807 133.5 99 132.381 99 131L99 119C99 117.619 97.8807 116.5 96.5 116.5L85.7297 116.5L82.9278 130.51Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M64.5506 131.31C64.707 132.561 65.7705 133.5 67.0313 133.5L80.2209 133.5C81.4127 133.5 82.4387 132.659 82.6724 131.49L85.0723 119.49C85.3817 117.943 84.1984 116.5 82.6208 116.5L65.5312 116.5C64.0274 116.5 62.8639 117.818 63.0505 119.31L64.5506 131.31Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
    />
    <path
      d="M75.7344 124.99L71.5 124.99"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="16"
      cy="16"
      r="14.5"
      transform="matrix(-1 0 0 1 140.5 123)"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M122.624 132C118.758 133.036 116.464 137.01 117.5 140.876"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="16"
      cy="16"
      r="14.5"
      transform="matrix(-1 0 0 1 214.5 123)"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M196.624 132C192.758 133.036 190.464 137.01 191.5 140.876"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_9_68854">
        <rect width="64" height="248" fill="white" transform="translate(283.5 45) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);
