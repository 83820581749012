import React from 'react';
import { Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { HouseStoreIcon } from 'common/icons/house-store-icon';
import { GeoIcon } from 'common/icons/geo-icon';
import { HumanIcon } from 'common/icons/human-icon';
import { PalitreIcon } from 'common/icons/palitre-icon';
import { CoopIcon } from 'common/icons/coop-icon';
import { SamplesIcon } from 'common/icons/samples-icon';
import {
  localSuppliers,
  number,
  numbers,
  numberText,
  summary,
  whatIsLmContainer,
} from './new-lemana.styles';

export const NewLemana: React.FC = () => {
  const { mobile } = useBreakpoints() || {};
  const { t } = useTranslation();
  return (
    <section className={whatIsLmContainer}>
      <div className={numbers}>
        <div className={number}>
          <HouseStoreIcon />
          <div>
            <Typography variant="h1">1</Typography>
            <Typography variant="m" size="body_short">
              {t('rebrandingKZ.newLemana.storesCount')}
            </Typography>
          </div>
        </div>
        <div className={number}>
          <GeoIcon mobile={mobile} />
          <div>
            <Typography variant="h1">28</Typography>
            <Typography variant="m" size="body_short">
              {t('rebrandingKZ.newLemana.citiesCountForDelivery')}
            </Typography>
          </div>
        </div>
        <div className={number}>
          <div style={{ width: '49px', height: '48px' }}>
            <HumanIcon mobile={mobile} />
          </div>
          <div className={number}>
            <Typography variant="h1">370&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              {t('rebrandingKZ.newLemana.employees')}
            </Typography>
          </div>
        </div>
        <div className={number}>
          <PalitreIcon mobile={mobile} />
          <div>
            <Typography variant="h1">320&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              {t('rebrandingKZ.newLemana.kindServices')}
            </Typography>
          </div>
        </div>
        <div className={number}>
          <CoopIcon />
          <div className={numberText}>
            <Typography variant="h1">100&nbsp;+</Typography>
            <Typography variant="m" size="body_short" className={localSuppliers}>
              {t('rebrandingKZ.newLemana.localSuppliers')}
            </Typography>
          </div>
        </div>
        <div className={number}>
          <CoopIcon fill />
          <div className={numberText}>
            <Typography variant="h1">595&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              {t('rebrandingKZ.newLemana.suppliers')}
            </Typography>
          </div>
        </div>
        <div className={number}>
          <SamplesIcon mobile={mobile} />
          <div>
            <Typography variant="h1">10&nbsp;000&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              {t('rebrandingKZ.newLemana.localAssortment')}
            </Typography>
          </div>
        </div>
        <div className={number}>
          <SamplesIcon mobile={mobile} fill />
          <div>
            <Typography variant="h1">40&nbsp;000&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              {t('rebrandingKZ.newLemana.assortment')}
            </Typography>
          </div>
        </div>
      </div>
      <Typography variant="l" size="body_short" color="text-primary" className={summary}>
        {t('rebrandingKZ.newLemana.summary')}
      </Typography>
    </section>
  );
};
