import { useTranslation } from 'react-i18next';

export const useHistoryItems = () => {
  const { t } = useTranslation();
  return [
    {
      years: '2004',
      imgUrl:
        'https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/static-pages/about-us/image%2047.png/file.png',
      description: t('rebrandingKZ.historyCarousel.historyItems.2004'),
    },
    // TODO: загрузить картинки
    {
      years: '2018',
      imgUrl:
        'https://cdn.lemanapro.ru/lmru/image/upload/v1718704112/fudzi/navigation-microfronts/almaty.png/almaty.png',
      description: t('rebrandingKZ.historyCarousel.historyItems.2018'),
    },
    {
      years: '2019',
      imgUrl:
        'https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/static-pages/about-us/image%2030.png/file.png',
      description: t('rebrandingKZ.historyCarousel.historyItems.2019'),
    },
    {
      years: '2019',
      imgUrl:
        'https://cdn.lemanapro.ru/lmru/image/upload/v1718704112/fudzi/navigation-microfronts/pro.png/pro.png',
      description: t('rebrandingKZ.historyCarousel.historyItems.text2'),
    },
    {
      years: '2024',
      imgUrl:
        'https://cdn.lemanapro.ru/lmru/image/upload/v1718704112/fudzi/navigation-microfronts/brand.png/brand.png',
      description: t('rebrandingKZ.historyCarousel.historyItems.2024'),
    },
  ];
};
