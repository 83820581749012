import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Cards, Icon } from 'common/components';

export const CardsSection: React.FC = () => {
  const { t } = useTranslation();
  const cardsContent: { icon: Icon; text: string }[] = [
    {
      icon: 'box',
      text: t('rebrandingKZ.cards.supplier'),
    },
    {
      icon: 'lmpro',
      text: t('rebrandingKZ.cards.goodsAndServices'),
    },
    {
      icon: 'platform',
      text: t('rebrandingKZ.cards.platformCompany'),
    },
    {
      icon: 'care',
      text: t('rebrandingKZ.cards.environment'),
    },
  ];

  return (
    <Cards>
      {cardsContent.map(({ icon, text }) => (
        <Card key={text} icon={icon}>
          {text}
        </Card>
      ))}
    </Cards>
  );
};
