type DeliveryStaticDataType = {
  [key: string | 'default']: {
    header: string;
    isDefaultGeneralInfo: boolean;
    isDefaultPickupText: boolean;
    deliveryNameGenitive?: string;
  };
};

export const DeliveryStaticData: DeliveryStaticDataType = {
  belgorod: {
    header: 'Курьером от 350 ₽',
    isDefaultGeneralInfo: false,
    isDefaultPickupText: false,
    deliveryNameGenitive: 'Курске',
  },
  orel: {
    header: 'Курьером и в пункты выдачи от 1 ₽',
    isDefaultGeneralInfo: false,
    isDefaultPickupText: true,
    deliveryNameGenitive: 'Курске',
  },
  'stary-oskol': {
    header: 'Курьером от 390 ₽',
    isDefaultGeneralInfo: false,
    isDefaultPickupText: false,
    deliveryNameGenitive: 'Воронеже',
  },
  default: {
    header: 'Курьером и в пункты выдачи от 1 ₽',
    isDefaultGeneralInfo: true,
    isDefaultPickupText: true,
  },
};
