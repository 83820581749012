import React, { FC, PropsWithChildren } from 'react';
import { LinkButton, Typography } from 'fronton-react';
import { ChevronRightIcon } from '@fronton/icons-react';
import { patchUrlByLocale } from 'utils';
import { CardImg, Container, Content, IconContainer } from './styles';
import { Icon } from './types';
import { BoxIcon, LmproIcon, CareIcon, PlatformIcon } from './assets';

type Props = {
  icon: Icon;
  href?: string;
  linkText?: string;
  onClick?: () => void;
} & PropsWithChildren;

const icons: Record<Icon, React.ElementType> = {
  box: BoxIcon,
  lmpro: LmproIcon,
  care: CareIcon,
  platform: PlatformIcon,
};

export const Card: FC<Props> = ({ icon, children, linkText, href, onClick }) => {
  const IconComponent = icons[icon];
  return (
    <Container>
      <CardImg>
        <IconContainer>
          <IconComponent />
        </IconContainer>
      </CardImg>
      <Content>
        <Typography size="body_accent" variant="m" color="text-primary">
          {children}
        </Typography>
        {href && linkText && (
          <LinkButton
            iconRight={<ChevronRightIcon type="outline" size="l" color="brand-dark" />}
            variant="accent"
            size="m"
            color="brand-dark"
            as="a"
            href={patchUrlByLocale(href)}
            onClick={onClick}
          >
            {linkText}
          </LinkButton>
        )}
      </Content>
    </Container>
  );
};
