import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { cx } from 'linaria';
import { phoneClass } from './styles';
import { PHONE_NUMBER } from 'common/constants';
import { PhoneLinkBase } from 'common/components/phone-link-base';

export const DATA_QA_STUB_REGION_PHONE_NUMBER = 'stub-region-phone-number';
export const DATA_QA_STUB_REGION_PHONE_NUMBER_LINK = 'stub-region-phone-number-link';

type Props = {
  className?: string;
  onClick: () => void;
  dataQA?: string;
};

export const PhoneNumber: FC<Props> = ({ className, onClick, dataQA }) => (
  <PhoneLinkBase data-qa={DATA_QA_STUB_REGION_PHONE_NUMBER_LINK} className={cx(className, phoneClass)}>
    <Typography
      size="body_accent"
      variant="s"
      data-qa={dataQA || DATA_QA_STUB_REGION_PHONE_NUMBER}
      onClick={onClick}
    >
      {PHONE_NUMBER}
    </Typography>
  </PhoneLinkBase>
);
