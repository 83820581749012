import React, { FC, useCallback } from 'react';
import { RegularButton, Typography } from 'fronton-react';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { analyticsUtils } from 'utils';
import { buttonContainer, container, description } from './styles';
import { createMainUrl } from 'common/constants';
import { NoStoreIcon, StoreIcon } from 'common/icons';
import { getStubRegionDelivery } from 'features/stub-region/ducks/get-stub-region-data';
import { useSelector } from 'react-redux';

export const DATA_QA_MAIN_INFO_TITLE = 'region-stub-main-info-title';
export const DATA_QA_MAIN_INFO_BUTTON = 'region-stub-main-info-button';

type Props = {
  title: string;
  buttonText: string;
  regionLink: string;
  children?: React.ReactNode;
};

export const MainInfo: FC<Props> = ({ title, buttonText, regionLink, children }) => {
  const { largeDesktop, desktop } = useBreakpoints() || {};
  const isDesktopScreen = largeDesktop || desktop;

  const sandGAEvent = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'stubPage',
      elementName: title,
      elementText: buttonText,
    });
  }, [buttonText, title]);

  return (
    <div className={container}>
      <div className={description}>
        <Typography variant="h1" data-qa={DATA_QA_MAIN_INFO_TITLE}>
          {title}
        </Typography>
        {children}
        <GoToShoppingButton text={buttonText} link={regionLink} onClick={sandGAEvent} />
      </div>
      {isDesktopScreen && <ImageBlock />}
    </div>
  );
};

const ImageBlock = () => {
  const hasDelivery = useSelector(getStubRegionDelivery);

  if (hasDelivery) return <StoreIcon />;
  return <NoStoreIcon />;
};

type GoToShoppingButtonProps = {
  text: string;
  link: string;
  onClick: () => void;
};

const GoToShoppingButton = (props: GoToShoppingButtonProps) => {
  const { mobile } = useBreakpoints() || {};

  return (
    <div className={buttonContainer}>
      <RegularButton
        invert
        size="l"
        variant="accent"
        as="a"
        wide={mobile}
        href={createMainUrl(props.link)}
        onClick={props.onClick}
        data-qa={DATA_QA_MAIN_INFO_BUTTON}
      >
        {props.text}
      </RegularButton>
    </div>
  );
};
